import { Route, Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { clinicRoutes } from '../../router';
import { isAuthenticated } from "../../utils";
import { useEffect } from "react";

const PrivateRouter = () => {
    useEffect(() => {
        localStorage.getItem('hap_cl_token')
    }, [isAuthenticated()])
    return (
        clinicRoutes.map(({ path, Component, title }, key) => (
            <Route exact path={path} render={(props) =>
                (localStorage.getItem('hap_cl_token') ? localStorage.getItem('hap_cl_token') : " ") && isAuthenticated() ? <>
                    {title &&
                        <Helmet>
                            <title>{'Haploscope Clinic'}</title>
                        </Helmet>
                    }
                    <Component {...props} />
                </> : (<Redirect to={{ pathname: '/', user: isAuthenticated, state: { from: props.location } }} />)
            } key={key} />
        ))
    )
};

export default PrivateRouter
