import React, {useEffect, useState, useRef, useCallback} from "react";
import DatePicker, { registerLocale } from 'react-datepicker';
import {useForm,Controller} from "react-hook-form";
import moment from "moment";
import {currentTime, generateAvatar} from "../../../utils";
import {
    addAppointment, clearPatientData,
    getAllPatientsForSchedule,
    getAvailableMinuteForSchedule,
    getCalenderSchedule, getPatientScheduleByDate
} from "../../../redux/Schedule/action";
import {useDispatch, useSelector} from "react-redux";
import Validation from "../../Validation";
import FilledButton from "../../FilledButton";
import Loader from "../../Loader";
import debounce from 'lodash.debounce';

const AddAppointmentPop = ({modalVal,handleOpenModal}) => {
    const listInnerRef = useRef();
    const dispatch = useDispatch();
    const patient_data = useSelector(state => state.scheduleReducer.patient_data)
    const { availableMinutes, isUnlimited } = useSelector(state => state.scheduleReducer.reqMinutes)
    const allPatients = useSelector(state => state.scheduleReducer.patients)
    const [loading, setLoading] = useState(false)
    const [loaderPatient, setLoaderPatient] = useState(false)
    const { register, watch, formState: { errors }, handleSubmit, setValue, reset,control ,getValues} = useForm({ mode: "all", defaultValues: { date: null } });

    const [appointmentState, setAppointmentState] = useState({
        physician_id: patient_data?.physician?.[0]?._id,
        patient_id: modalVal,
        date: null,
        startAt: null,
        callLength: '45',
        note: '',
        caregivers: []
    })

useEffect(()=>{

    getAvailableMinute()
},[])

const getAvailableMinute =() =>{
        dispatch(getAvailableMinuteForSchedule({physician_id:patient_data?.physician?.[0]?._id}))
}

    const searchHandler = (e)=>{
        let payload = {
            physician_id: patient_data?.physician?.[0]?._id,
            name: e.target.value,
            status: true,
            page: allPatients.page,
            limit: 10
        }
        dispatch(getAllPatientsForSchedule(payload))
    }

    const debouncedChangeHandler = useCallback(
        debounce(searchHandler, 1000)
        , []);

const patientListScroll = async ()=>{
    if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if(scrollTop === 0){
            const payload = {
                physician_id: patient_data?.physician?.[0]?._id,
                page: 1,
                name: " ",
                limit: 10,
                status: true,
            }
            dispatch(getAllPatientsForSchedule(payload))
        }
        if (scrollTop + clientHeight === scrollHeight && allPatients?.totalResults > allPatients?.AllPatientScheduleList?.length) {
            const payload = {
                physician_id: patient_data?.physician?.[0]?._id,
                page: 1,
                name: " ",
                limit: allPatients.limit + 10,
                status: true,
            }
            dispatch(getAllPatientsForSchedule(payload))
        }

    }
}
    const addAppointmentHandler = (state)=>{

        let date = moment(moment(state.date).format('YYYY-MM-DD') + " " + moment(state.startAt).format('HH:mm:ss')).format();
        let startAt =  Object.assign({ hour: moment.utc(state?.startAt).hours(), min: moment.utc(state?.startAt).minutes(), sec: moment.utc(state?.startAt).seconds() })
        let payload={
            date: moment.utc(date).format(),
            callLength: Number(appointmentState?.callLength),
            physician_id:patient_data?.physician?.[0]?._id,
            patient_id: appointmentState.patient_id,
            caregivers:[],
            startAt,
            note:state?.note
        }
        setLoading(true)
      dispatch(addAppointment(payload)).then(res => {
          if (res.status === 200) {
              setLoading(false)
              let payload = {
                  patient_id: modalVal
              }
              dispatch(getCalenderSchedule(payload))
              getAllSchedule()
              handleOpenModal('CommonPop', { header: "Success", body: res.data.message, auth: true })
          }
          else {
              setLoading(false)
              handleOpenModal('CommonPop', { header: "Error", body: res.data.message, auth: true })
          }
      }).catch(e => {
          setLoading(false)
          handleOpenModal('CommonPop', { header: "Error", body: e.message, auth: true })
      })
    }
    const getAllSchedule = () => {
        const req = {
            patient_id: modalVal,
            date: moment(new Date()).startOf('days').toISOString(),
            page: 1,
            limit: 5
        }
        dispatch(getPatientScheduleByDate(req))
    }
    const handleCallLength = (e)=>{
        const { name, value } = e.target;
        setAppointmentState({
            ...appointmentState,
            [name]: value
        })
    }
    const handlePatientsSelect = (e)=>{
        const { name, value } = e.target;
        setAppointmentState({
            ...appointmentState,
            [name]: value
        })
    }
    const clearDataHandler = ()=>{
        reset();
        setAppointmentState({
            ...appointmentState,
            date: null,
            startAt: null,
            callLength: '45',
            note: '',
        })
    }


    useEffect(()=>{
        reset()
        // dispatch(clearPatientData())
        setAppointmentState({
            ...appointmentState,
            patient_id: modalVal,
            date: null,
            startAt: null,
            callLength: '45',
            note: '',
        })
    },[handleOpenModal])
    return (
        <div className='main_comman_modal appoinment-modal_block'  >
            <div className='main_addappoinment'>
                <h4>{`Add Appointment for Dr. ${patient_data?.physician?.[0].first_name + " " + patient_data?.physician?.[0].last_name}`}</h4>
                <div className='sub_appoinment_block'>
                    <form className='addappoinment_block' onSubmit={handleSubmit(addAppointmentHandler)}>
                        {/* date and time */}
                        <div className="form_row appoinment_time">
                            <div className="form_group">
                                <label>Date</label>
                                <span className='date_block'>
                              <Controller
                                  name="date"
                                  control={control}
                                  rules={{ required: 'date is Required' }}
                                  render={({
                                               field: { onChange, onBlur, value, name, ref },
                                               fieldState: { invalid, isTouched, isDirty, error },
                                               formState,
                                           }) => (
                                              <DatePicker
                                                  onChange={onChange}
                                                  className={`form_control ${watch("date")}`}
                                                  selected={value}
                                                  dateFormat="MMM d, yyyy"
                                                  minDate={new Date()}
                                                  placeholderText="Select Date"
                                                  dropdownMode="select"
                                              />
                                  )}
                                  defaultValue={''}
                              />

                                </span>
                                <div className="invalid-feedback pl-4 d-block errorMsg">
                                    <Validation errors={errors.date} message={errors?.date?.message} watch={watch("date")} />
                                </div>
                            </div>
                            <div className="form_group">
                                <label>Starts At</label>
                                <span className='time_block'>
                                         <Controller
                                             name="startAt"
                                             control={control}
                                             rules={{ required: 'Start At is Required' }}
                                             render={({
                                                          field: { onChange, onBlur, value, name, ref },
                                                          fieldState: { invalid, isTouched, isDirty, error },
                                                          formState,
                                                      }) => (
                                            <DatePicker
                                                className={`form_control ${watch('Start At')}`}
                                                selected={value}
                                                onChange={onChange}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeCaption="Start At"
                                                dateFormat="h:mm aa"
                                                timeIntervals={1}
                                                minTime={moment(moment.utc(watch("date")).local()).isSame(moment(), 'day') ? new Date() : new Date(watch("date"))}
                                                maxTime={moment().endOf('days').toDate()}
                                            />
                                                     )}
                                                     defaultValue={''}
                                                 />
                                     <span className='pst'>{`${currentTime.format("z")}`}</span>
                                </span>

                                <div className="invalid-feedback pl-4 d-block errorMsg">
                                    <Validation errors={errors.startAt} message={errors?.startAt?.message} watch={watch("startAt")} />
                                </div>
                            </div>
                        </div>
                        {/* call length */}
                        <div className='main_call_length'>
                            <p>Call Length</p>
                            <div className='request_min_block min_block d_flex'>
                                <div className='radio_buttons_block'>
                                    <div className="min_block">
                                        <input type="radio" disabled={Number(availableMinutes) < 30 && !isUnlimited} name="callLength" value={30} checked={appointmentState.callLength === '30'} {...register('callLength',{
                                            onChange:(e) =>handleCallLength(e)
                                        })} />
                                        <span className="check" />
                                        <label>30 min</label>
                                    </div>
                                    <div className="min_block">
                                        <input type="radio" disabled={Number(availableMinutes) < 45 && !isUnlimited}  name="callLength"  value={45} checked={ isUnlimited ? appointmentState.callLength === '45'  : availableMinutes < 45 && !isUnlimited ? false : appointmentState.callLength === '45'} {...register('callLength',{
                                            onChange:(e) =>handleCallLength(e)
                                        })} />
                                        <span className="check" />
                                        <label>45 min</label>
                                    </div>
                                </div>

                                {(Number(availableMinutes) > 0 || Number(availableMinutes) === 0) && !isUnlimited &&
                                <div className='available_min_block'>
                                    <span>(available minutes: {Math.floor(Number(availableMinutes))})</span>
                                </div>
                                }
                                {
                                    isUnlimited &&
                                    <div className='available_min_block'>
                                        <span>(available minutes: {'Unlimited'})</span>
                                    </div>
                                }

                            </div>
                            <div className="invalid-feedback pl-4 d-block errorMsg">

                            </div>
                        </div>
                        {/* note textarea */}
                        <div className='main_note_block form_group'>
                            <textarea className='form_control' name='note' placeholder='Note' {...register('note')}/>
                            <span>Optional</span>
                        </div>
                        {/* select Patient */}
                        <div className='select_patient_block'>
                            <h6>Select Patient</h6>
                            {

                                <div className='current_patient_box'>

                                    <div className='current_patient_header'>
                                        <div className="form_group search">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="form_control search_input"
                                                name="search"
                                                onChange={(e)=>debouncedChangeHandler(e)}
                                            />
                                            <span className="search_icon">
                                                <img src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHRSURBVHgBrVTLbcJAEJ01H3EkHTgd2BckxCGhhFQQOggdEDrAHUAFlAAXBDc7FYQSOCK+eW/ZtRbH4GDlSZbX6503b2ZnRkkO4jhu7na7vud5L+fzOcBWE89GKZXgnRwOh6jdbq/lBlSWbL/ff2L5IQWAs1G9Xh+GYbjJ/vPsYrlc+iCMHcIxntdarfbUarUU3/w2+wLVfUQT0y5XqVFIQh8K1gjvrdPpJDdEagGVSmVmz0Nx6CrWSk3I+sDpdOreIySYz+Px2MVyDcU+FA+ulBqv3/zAwed7F5DFYrEIkBZGKEyPVetVq9U0h48QEiaiMddIWXq5DD+wpFICiG7CN1L3mpKaOqT8LymBRqNh8x+4SlnYkldvf4Fj13RJ9SbLSkrAsbsqKS1/u90GUgK4IGuXlqGHOptzgbJ6lxLAnWg7MxcupCipyKx7rDt5AKZFe3JRHKWkTDS8jPgBB9O8Xr5FaFpVDxe3xj1DNhTTchh3syJiRmR736icuP9VjmffbI3hZAKHCaPhLfNSTA57LomdGVbtr3kKwwEO9aUADJkK0TRTcYYRiVWeAVVzJphuSyc/ngR7c4y6yBZ9dgySWMk/wCUGaWGUDxGvVis9qX4ArLAOUu34bGcAAAAASUVORK5CYII="} alt="Search_Icon" />
                                            </span>
                                        </div>
                                        <h6>Current Patients</h6>
                                    </div>

                                    <div className='main_cp_list' ref={listInnerRef} onScroll={patientListScroll} >
                                        {loaderPatient?
                                            <Loader className={'content-loader-transfer patients_loader'}/>
                                            :
                                            allPatients?.AllPatientScheduleList?.length > 0 ?
                                            allPatients?.AllPatientScheduleList?.map((item, i) => {

                                                return (
                                                    <>
                                                        <div className='main_cp_list_col' key={i}>
                                                            <div className='cp_profile_block'>
                                                                <div className='profile'>
                                                                    <img
                                                                        src={(!item?.profile || item?.profile === null || item?.profile === "NA" || item?.profile === "") ? generateAvatar(item.first_name + " " + item.last_name) : item?.profile}
                                                                        alt=""/>
                                                                </div>
                                                                <span
                                                                    className='cp_name'>{item.first_name + " " + item.last_name}</span>
                                                            </div>
                                                            <div className='cp_checkbox'>
                                                                <div className="form_group checkbox selectall_checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        name='patient_id'
                                                                        className={`${watch('patient_id')}`}
                                                                        defaultValue={item._id}
                                                                        checked={appointmentState.patient_id === item._id}
                                                                        {...register('patient_id',{
                                                                            required:"The patients field is required.",
                                                                            onChange:(e) => handlePatientsSelect(e, item)
                                                                        })}
                                                                    />
                                                                    <span className="checkbox_clone"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                            :
                                            <div className='nodata'>No Data Found</div>
                                        }
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                                <Validation errors={errors.patient_id} message={errors?.patient_id?.message} watch={watch("patient_id")} />
                                            </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="form_group d_flex justify_content_center form_action appoinment_btn">
                            <button className="btn btn_default" type="reset" onClick={() => clearDataHandler()}>Clear</button>
                            <FilledButton type="submit" loading={loading} value={'Submit'} className="btn btn_primary"  />
                            {/*<button className="btn btn_primary" type="submit" >Submit</button>*/}

                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}
export default AddAppointmentPop