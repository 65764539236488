let PopComponent = {};
//auth pop
PopComponent['VerificationCodePop'] = require('../component/Popup/AuthPop/VerificationCodePop').default;
PopComponent['AccountcredentialsPop'] = require('../component/Popup/AuthPop/AccountcredentialsPop').default;
PopComponent['ForgotPasswordPop'] = require('../component/Popup/AuthPop/ForgotPasswordPop').default;

//Patient
PopComponent['AddPatientPop'] = require('../component/Popup/PatientPop/AddPatientPop').default;
PopComponent['DeletePatientPop'] = require('../component/Popup/PatientPop/DeletePatientPop').default;
PopComponent['PatientsStatusUpdatePop'] = require('../component/Popup/PatientPop/PatientsStatusUpdatePop').default;

//success and Error common pop
PopComponent['CommonPop'] = require('../component/Popup/CommonPop').default;
//Physician
PopComponent['DeletePhysicianPop'] = require('../component/Popup/PhysicianPop/DeletePhysicianPop').default;
PopComponent['PhyStatusUpdatePop'] = require('../component/Popup/PhysicianPop/PhyStatusUpdatePop').default;
PopComponent['UpdatePhysicianPop'] = require('../component/Popup/PhysicianPop/UpdatePhysicianPop').default;
PopComponent['TransferPatientsPop'] = require('../component/Popup/PhysicianPop/TransferPatientsPop').default;
PopComponent['AddMinutePop'] = require('../component/Popup/PhysicianPop/AddMinutePop').default;
PopComponent['AddPhysician'] = require('../component/Popup/PhysicianPop/AddPhysician').default;
//clinic data
PopComponent['AuthorizedPop'] = require('../component/Popup/Clinic/AuthorizedPop').default;
PopComponent['editAuthorizedPop'] = require('../component/Popup/Clinic/editAuthorizedPop').default;
PopComponent['SwitchPrimaryPop'] = require('../component/Popup/Clinic/SwitchPrimaryPop').default;
PopComponent['AddPhysicianPop'] = require('../component/Popup/Clinic/AddPhysicianPop').default;
PopComponent['DeleteAuthorizedPop'] = require('../component/Popup/Clinic/DeleteAuthorizedPop').default;
PopComponent['AuthorizedUserStatusPop'] = require('../component/Popup/Clinic/AuthorizedUserStatusPop').default;
PopComponent['EditClinicDetailPop'] = require('../component/Popup/Clinic/EditClinicDetailPop').default;
PopComponent['ChangeMinutes'] = require('../component/Popup/Clinic/ChangeMinutes').default;
PopComponent['ChangeMinutesPlanBtn'] = require('../component/Popup/Clinic/ChangeMinutesPlanBtn').default;
PopComponent['CancelMinutesPlanBtn'] = require('../component/Popup/Clinic/CancelMinutesPlanBtn').default;
// EnablePhyStatusPop
PopComponent['EnablePhyStatusPop'] = require('../component/Popup/PhysicianPop/EnablePhyStatusPop').default;
PopComponent['CaregiversPop'] = require('../component/Popup/PatientPop/CaregiversPop').default;
//BillingPop
PopComponent['AdditionalMinutes'] = require('../component/Popup/BillingPop/AdditionalMinutes').default;
PopComponent['AddAppointmentPop'] = require('../component/Popup/Appointment/AddAppointmentPop').default;
PopComponent['DeleteAppointment'] = require('../component/Popup/Appointment/DeleteAppointment').default;
PopComponent['EditAppointmentPop'] = require('../component/Popup/Appointment/EditAppointmentPop').default;
PopComponent['AssignPhysician'] = require('../component/Popup/Appointment/AssignPhysician').default;
export default PopComponent