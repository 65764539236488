import React, {useState} from "react";
import FilledButton from "../../FilledButton";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {deleteAppointment} from "../../../redux/Schedule/action";

const DeleteAppointment = ({handleOpenModal,modalVal})=>{
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { handleSubmit } = useForm({ mode: "all" });
    const deleteAppointmentHandler =()=>{
        let payload = {
            id: modalVal.id,
            physician_id: modalVal.physician_id
        }
        setLoading(true)
        dispatch(deleteAppointment(payload,modalVal.type)).then(res => {
            if (res.status === 200) {
                setLoading(false)
                handleOpenModal('CommonPop', { header: "Success", body: res.data.message, auth: true })
            }
            else {
                setLoading(false)
                handleOpenModal('CommonPop', { header: "Error", body: res.data.message, auth: true })
            }
        }).catch(e => {
            setLoading(false)
            handleOpenModal('CommonPop', { header: "Error", body: e.message, auth: true })
        })
    }
    return(
        <div className='main_delete_modal'>
            <h4>Delete a Appointment?</h4>
            <div className='delete_content'>
                <p>You are about to delete an active patient from your list. once you delete the patient, all calendar invites will be removed from your and the patient’s schedule. This change can not be reversed.</p>
            </div>
            <span className='proceed'>Do you want to proceed?</span>
            <form onSubmit={handleSubmit(deleteAppointmentHandler)} className="form_group d_flex justify_content_center form_action delete_modal_btn">
                <FilledButton type="submit" loading={loading} value={'Yes'} className="btn btn_default" loader_class={'btn_loader_red'} />
                <button className="btn btn_primary" type={'reset'} onClick={() => handleOpenModal()}>No</button>
            </form>
        </div>
    )
}

export default DeleteAppointment