import close from "../../assets/images/close.svg"
import history from "../../history";

const CommonPop = ({ modalVal, handleOpenModal }) => {
    const { header, body, path, auth,isClinicEdit } = modalVal;
    return (
        <div className="delete-pop">
            <div className="title">
                {header}
            </div>
            <div className="desc">
                {body}
            </div>
            <div>
                {isClinicEdit?
                    <button className="btn btn_primary  mt_2" onClick={() => history.goBack()} > Ok</button>
                    :
                    auth ?
                        <button className="btn btn_primary  mt_2" onClick={() => handleOpenModal()} > Ok</button>
                        :
                        <button className="btn btn_primary  mt_2" onClick={() => history.push(path)} > Ok</button>
                }
            </div>
        </div>
    )
}
export default CommonPop