import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import FilledButton from '../component/FilledButton';
import { useDispatch } from "react-redux";
import { ResetPasswordData } from '../redux/auth/action';
import logo from '../assets/images/header_logo.svg';
import CustomModal from "../hoc/CustomModal";
import PopContent from "../hoc/PopContent";
import Cookies from 'js-cookie';


const ResetPassword = () => {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false)
    const [formRef, setFormRef] = useState(null);
    const [openModal, setModalOpen] = useState(false);
    const [modalVal, setModalVal] = useState('')
    const [modalName, setModalName] = useState('')
    let Modal = PopContent[modalName]
    const [form] = Form.useForm();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const id = params.get('id');
    const dispatch = useDispatch();

useEffect(()=>{
    localStorage.clear();
    Cookies.remove('x-access-token')
},[token])
    const handleChangePassword = (value) => {
        setLoading(true)
        Object.assign(value, { token: token })
        Object.assign(value, { id: id })
        delete value.confirmPassword

        dispatch(ResetPasswordData(value)).then(res => {
            if (res.status === 200) {
                setLoading(false)
                handleOpenModal('CommonPop', { header: "Success", body: 'Password Added Successfully', path: "/" })
            } else {
                setLoading(false)
                handleOpenModal('CommonPop', { header: "Info", body: res.data.message })
            }
        })
    }

    const handleCloseModal = () => {
        setFormData({})
        form.resetFields();
    }
    useEffect(() => {
        setFormRef(form)
    }, [])
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    };

    const handleOpenModal = (type, data) => {
        switch (type) {
            case 'CommonPop': {
                setModalVal(data)
                setModalName(type);
                setModalOpen(true)
                break
            }
            default: {
                setModalOpen(!openModal);
            }
        }
    }
    return (
        <>
            {/* <header>
                {

                    <div className="container_fluid">
                        <div className="row main_herader_row">
                            <div className="header px_auto">
                                <div className="logo"><img src={logo} alt="logo_img" /></div>

                            </div>
                        </div>
                    </div>
                }
            </header> */}
            <div className='content_wrapper resetpssword_main'>
                {/* <div className='main_clinic_header_block'>
                    <div className="inner_header clinic_header">
                        <span className='breadcrumb text_uppercase montserrat_bold'>ResetPassword</span>
                    </div>
                </div> */}
                <div className='setting_form resetpsw_sub_block'>
                    <div className="header px_auto">
                        <div className="logo"><img src={logo} alt="logo_img" /></div>
                    </div>
                    <div className='resetpsw_form_block'>
                        {/* <p className='successfully_msg'>Your password has been reset successfully. You may now log into the mobile app using your new password.</p> */}
                        <h4>Create New Password</h4>
                        <Form
                            className='setting_form resetpsw_form'
                            form={form}
                            onFinish={handleChangePassword}
                            layout="vertical"
                            autoComplete="off"
                            initialValues={formData}
                            onReset={() => handleCloseModal()}
                        >

                            <Form.Item
                                name="password"
                                onChange={(e) => handleChange(e)}
                                rules={[
                                    { required: true, message: 'Please input your New password!' },
                                    {
                                        pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                        message: "Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit."
                                    },
                                    {
                                        maxLength: 8,
                                        message: "Password should be at-least 8 characters.",
                                    }
                                ]}
                            >
                                <Input.Password
                                    name="password"
                                    onChange={(e) => handleChange(e)} placeholder={'New Password'} />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                onChange={(e) => handleChange(e)}
                                rules={!formData.confirmPassword
                                    ? [{ required: true, message: 'Please input your Confirm password!' }]
                                    : [{
                                        validator: (_, value) => {
                                            if (formData.password !== value) {
                                                return Promise.reject('Confirm password is not match with new password');
                                            } else {
                                                return Promise.resolve();
                                            }
                                        }
                                    }]}
                            >

                                <Input.Password name="confirmPassword" placeholder={'Retype New Password'} onChange={handleChange} />
                            </Form.Item>
                            <div className="form_buttons">
                                <button type="reset" className="btn btn_default" onClick={() => handleCloseModal()} >Clear</button>
                                <FilledButton type="submit" loading={loading} value={'Submit'} className="btn btn_primary ml_2" />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <CustomModal className={`modal errorPop`} modalIsOpen={openModal} handleOpenModal={handleOpenModal}>
                <Modal handleOpenModal={handleOpenModal} modalVal={modalVal} />
            </CustomModal>
        </>
    )
};

export default ResetPassword;
