import React, { useEffect } from "react";
import Cookies from 'js-cookie';
import Loader from "./component/Loader";


const ErrorFallback = ({ error }) => {
    useEffect(() => {
        if (localStorage.getItem('counter') === "1") {
            window.location.reload(true)
            localStorage.removeItem('counter');
             localStorage.clear()
            Cookies.remove('x-access-token')
            return false
        }else{
            localStorage.setItem('counter', "1");
        }

        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (error?.message && chunkFailedMessage.test(error.message)) {
            window.location.reload()
        }
    }, [error]);
    return (<Loader className={'content-loader'}/>)
};


export default ErrorFallback