import { lazy } from 'react';
const Physicians = lazy(() => import('../pages/physicians'));
const Patients = lazy(() => import('../pages/patients'));
const PatientDetail = lazy(() => import('../pages/patients/PatientDetail'));
const SignUp = lazy(() => import('../pages/SignUp'));
const PhysicianDetail = lazy(() => import('../pages/physicians/PhysicianDetail'));
const UpdatePhysician = lazy(() => import('../pages/physicians/UpdatePhysician'));
const UpdatePatient = lazy(() => import('../pages/patients/UpdatePatient'));
const Clinic = lazy(() => import('../pages/Clinic'));
const Settings = lazy(() => import('../pages/Settings'));
const CientPhysicianDetail = lazy(() => import('../component/PhysicianDetails'));
const AddPhysician = lazy(() => import('../pages/physicians/AddPhysician'));
const Invoice = lazy(() => import('../pages/invoice'));
const Schedule = lazy(() => import('../pages/patients/Schedule'));

export const clinicRoutes = [
    //patients
    { path: '/patients', Component: Patients, title: "Patients" },
    { path: '/patient-detail/:id', Component: PatientDetail, title: "Patient Detail" },
    { path: '/edit-patient/:id', Component: UpdatePatient, title: "Edit Patient Detail" },
    //phy
    { path: '/physicians', Component: Physicians, title: "Physicians" },
    { path: '/add-physician', Component: AddPhysician, title: "Add Physicians" },
    { path: '/physician-detail/:id', Component: PhysicianDetail, title: "Physician Detail" },
    { path: '/edit-physician/:id', Component: UpdatePhysician, title: "Edit Physician" },
    //    clinic-detail
    { path: '/clinic-detail/:id', Component: Clinic, title: "Clinic Detail" },
    { path: '/settings', Component: Settings, title: "Settings" },
    // physician Details new page
    { path: '/physician-details', Component: CientPhysicianDetail, title: "CientPhysicianDetail" },
    { path: '/invoice/:id', Component: Invoice, title: "invoice" },
    { path: '/patients-schedule/:id', Component: Schedule, title: "Schedule" },
];

