import React, { useState } from "react";
import { Form, Input } from "antd";
import FilledButton from "../../FilledButton";
import { forgotPassword } from "../../../redux/auth/action";
import { useDispatch } from "react-redux";

const ForgotPassword = ({ handleOpenModal }) => {
    const dispatch = useDispatch(null);
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({});
    const [form] = Form.useForm();
    const handleCloseModal = () => {
        setFormData({})
        form.resetFields();
    }
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    };
    const handleForgotPassword = (value) => {
        setLoading(true)
        dispatch(forgotPassword(value)).then(res => {
            if (res.status === 200) {
                setLoading(false)
                handleOpenModal("CommonPop", { header: "success", body: res.data.message, auth: true })
            } else {
                setLoading(false)
                handleOpenModal("CommonPop", { header: "Info", body: res.data.message,auth: true  })
            }
        })
    }
    return (
        <div className='main_delete_modal forgot_psw_modal'>
            <h4>Forgot Password</h4>
            <div className='delete_content'>
                <Form
                    form={form}
                    onFinish={handleForgotPassword}
                    layout="vertical"
                    autoComplete="off"
                    initialValues={formData}
                    onReset={() => handleCloseModal()}
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        className={'ant-input-filed'}
                        onChange={(e) => handleChange(e)}
                        rules={[
                            { required: true, message: 'Please input your Email!' },
                            {
                                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                message: "Please enter a valid email",
                            },
                        ]}
                    >
                        <Input
                            name="email"
                            onChange={(e) => handleChange(e)} placeholder={'Email'} />
                    </Form.Item>
                    <div className="form_group d_flex justify_content_center form_action delete_modal_btn">
                        <button type={'reset'} className="btn btn_default" onClick={() => handleCloseModal()}>Clear</button>
                        <FilledButton type="submit" loading={loading} value={'Submit'} className="btn btn_primary" />

                    </div>
                </Form>
            </div>

        </div>
    )
}
export default ForgotPassword