import {GET_INVOICE_DATA,GET_CURRENT_BILLING_DATA} from "../../actionTypes";

export const getInvoiceData = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/getInvoiceData", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_INVOICE_DATA,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

//getCurrentBilling

export const getCurrentBilling = (payload) => async (dispatch, getState, api) => {
    return await api
        .get("/clinic/getcurrentBilling")
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_CURRENT_BILLING_DATA,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};