import {
    GET_AVAILABLE_MINUTE,
    GET_SCHEDULE_LIST,
    GET_SCHEDULE_PATIENT_DETAILS,
    SET_FILTER_SCHEDULE,
    GET_PATIENT_SCHEDULE_BY_DATE,
    GET_ALL_PATIENT_SCHEDULE_LIST,
    GET_SCHEDULE_STATUS,
    DELETE_APPOINTMENT_LIST,
    ADD_APPOINTMENT,
    EDIT_APPOINTMENT,
    UPDATE_APPOINTMENT, GET_PHYSICIAN_APPOINTMENT
} from "../../actionTypes";
import moment from "moment";
const initialState={
    patient_data:{},
    allSchedule:{
        scheduleList: [],
        limit: 5,
        page: 1,
        totalPages: 0,
        totalResults: 0,
        archivedCount:0,
        confirmCount:0,
        pendingCount:0
    },
    filters: {
        status: 'accepted'
    },
    reqMinutes: {
        availableMinutes: 0,
        isUnlimited: false
    },
    dateSchedule:{
        scheduleBy_date: [],
        limit: 5,
        page: 1,
        totalPages: 0,
        totalResults: 0
    },
    patients: {
        AllPatientScheduleList: [],
        limit: 10,
        page: 1,
        totalPages: 0,
        totalResults: 0
    },
    calendarScheduleStatus: [],
    editAppointment:null,
    getPhysicianAppointment:[]
}
export const scheduleReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_SCHEDULE_PATIENT_DETAILS: {
            let result = payload.length > 0 ? payload.reduce((acc,cur)=>({...cur,...acc})) : {};
            return {
                ...state,
                patient_data: {...result}
            }
        }
        case GET_SCHEDULE_LIST: {
            return {
                ...state,
                allSchedule:{
                    scheduleList: payload.docs,
                    limit: payload.limit,
                    page: payload.perPage,
                    totalPages: payload.totalPages,
                    totalResults: payload.total,
                    archivedCount:payload.archivedCount,
                    confirmCount:payload.cofirmCount,
                    pendingCount:payload.pendingCount
                }
            }
        }
        case SET_FILTER_SCHEDULE:{
            return {
                ...state,
                filters:{
                    status: payload
                }
            }
        }
        case GET_PATIENT_SCHEDULE_BY_DATE : {
            return {
                ...state,
                dateSchedule:{
                    scheduleBy_date: payload.docs,
                    limit: payload.limit,
                    page: payload.page,
                    totalPages: payload.pages,
                    totalResults: payload.total
                }
            }
        }
        case GET_ALL_PATIENT_SCHEDULE_LIST : {
            return {
                ...state,
                patients: {
                    AllPatientScheduleList: payload.docs,
                    limit: payload.perPage,
                    page: payload.currentPage,
                    totalPages: payload.totalPages,
                    totalResults: payload.total
                }
            };
        }
        case GET_SCHEDULE_STATUS:{
            return {
                ...state,
                calendarScheduleStatus: payload.dateList
            }
        }
        case GET_AVAILABLE_MINUTE:{
            if(payload.callLength){
                return {
                    ...state,
                    reqMinutes: {
                        availableMinutes: (state.reqMinutes.availableMinutes - Number(payload.callLength)),
                        billing_period_meeting_minutes: state.reqMinutes.billing_period_meeting_minutes,
                        isUnlimited:state.reqMinutes.isUnlimited
                    }
                };
            }else{
                return {
                    ...state,
                    reqMinutes: {
                        availableMinutes: payload.availableScheduleMinute,
                        billing_period_meeting_minutes: payload.billing_period_meeting_minutes,
                        isUnlimited: payload.isUnlimited
                    }
                };
            }
        }
        case DELETE_APPOINTMENT_LIST : {

            let result =  state.allSchedule.scheduleList?.filter(item=> item._id !== payload?.id)
              let scheduleBy_date = state.dateSchedule?.scheduleBy_date?.filter(item=>item._id !== payload?.id)

            return {
                ...state,
                allSchedule:{
                    ...state.allSchedule,
                    scheduleList: result
                },
                dateSchedule: {
                    ...state.allSchedule,
                    scheduleBy_date: scheduleBy_date
                }
            }
        }
          case  ADD_APPOINTMENT:{
              if(state.filters.status !== "pending") {
                  return {
                      ...state,
                      allSchedule: {
                          ...state.allSchedule,
                          pendingCount: state.allSchedule.pendingCount + 1,
                      },
                  };
              }
              if(state.allSchedule.scheduleList.length > 0){
                  return {
                      ...state,
                      allSchedule:{
                          ...state.allSchedule,
                          pendingCount:state.allSchedule.pendingCount + 1,
                          scheduleList: [...state.allSchedule.scheduleList, payload],
                      },
                      dateSchedule:{
                          ...state.dateSchedule,
                          scheduleBy_date: [...state.dateSchedule.scheduleBy_date, payload],
                      }
                  }
              }else{
                  return {
                      ...state,
                      allSchedule:{
                          ...state.allSchedule,
                          pendingCount:state.allSchedule.pendingCount + 1,
                          scheduleList: [payload],
                      },
                      dateSchedule:{
                          ...state.dateSchedule,
                          scheduleBy_date: [payload],
                      }
                  }
              }
          }


        case EDIT_APPOINTMENT : {
            return {
                ...state,
                editAppointment:payload
            }
        }
        case UPDATE_APPOINTMENT : {
            let temp= {...state}
            let index = state.allSchedule.scheduleList.findIndex(item=>item._id === payload._id)
            let scheduleindex = state.dateSchedule.scheduleBy_date.findIndex(item=>item._id === payload._id)
            // if(moment(state.allSchedule.scheduleList[index].date).format("MM/DD/YYYY") !== moment(payload.date).format("MM/DD/YYYY")){
            //     if(index > -1){
            //         temp.allSchedule.scheduleList[index].status = 'pending'
            //     }
            // }else{
                if(index > -1){
                    temp.allSchedule.scheduleList[index] = { ...temp.allSchedule.scheduleList[index], ...payload }
                    temp.dateSchedule.scheduleBy_date[scheduleindex] = { ...temp.allSchedule.scheduleList[scheduleindex], ...payload }
                }
            // }

            return temp
        }
        case GET_PHYSICIAN_APPOINTMENT : {
                return {
                    ...state,
                    getPhysicianAppointment:payload
                }
        }
        default:
            return state
    }
}

