import { combineReducers } from 'redux';
import loginReducer from '../auth/reducer';
import { physicianReducer } from "../physicians/reducer";
import { patientsReducer } from "../patients/reducer";
import { ClinicReducer } from "../clinic/reducer";
import {InvoiceReducer} from "../invoice/reducer";
import {scheduleReducer} from "../Schedule/reducer";

export default combineReducers({
    loginReducer: loginReducer,
    physicianReducer: physicianReducer,
    patientsReducer: patientsReducer,
    clinicReducer: ClinicReducer,
    invoiceReducer:InvoiceReducer,
    scheduleReducer:scheduleReducer
})