import { GET_PATIENTS_LIST, ADD_PATIENTS_LIST, GET_PATIENTS_DETAIL, DELETE_PATIENTS_LIST, EDIT_PATIENTS_DETAIL, UPDATE_PATIENTS_DETAIL,
    SEARCH_PATIENTS, BULK_UPLOAD_PATIENT, UPDATE_PATIENTS_LIST_STATUS,GET_CAREGIVERS_DATA } from "../../actionTypes";
import { jsonToFormData } from "../../../utils";

export const getPatientsList = () => async (dispatch, getState, api) => {
    return await api
        .get("/clinic/getPatients")
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_PATIENTS_LIST,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
///clinic/addPatient

export const addPatientsList = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/addPatient", jsonToFormData(payload))
        .then((res) => {
            if (res.status === 201) {
                dispatch({
                    type: ADD_PATIENTS_LIST,
                    payload: res.data.data.patient,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
///clinic/getPatientDetails
export const getPatientsDetail = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/getPatientDetails", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_PATIENTS_DETAIL,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const deletePatientsList = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/deletePatient", payload)
        .then((res) => {
            if (res.status === 201) {
                dispatch({
                    type: DELETE_PATIENTS_LIST,
                    payload: payload.id,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const resendInvitePatientsList = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/resendInvitationPatient", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};


export const editPatientsDetail = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/editPatient", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: EDIT_PATIENTS_DETAIL,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
///clinic/updatePatient
export const updatePatientsDetail = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/updatePatient", jsonToFormData(payload))
        .then((res) => {
            if (res.status === 201) {
                dispatch({
                    type: UPDATE_PATIENTS_DETAIL,
                    payload: payload,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
//
// ​/clinic​/search-patients
export const searchPatientList = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/search-patients", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: SEARCH_PATIENTS,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
///clinic/uploadPatient
export const uploadPatientBulk = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/uploadPatient", payload)
        .then((res) => {
            if (res.status === 201) {
                dispatch({
                    type: BULK_UPLOAD_PATIENT,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export const updatePatientsListStatus = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/updatePatientStatus", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: UPDATE_PATIENTS_LIST_STATUS,
                    payload: payload,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
//getCaregivers

export const getCaregiversData = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/getCaregivers", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_CAREGIVERS_DATA,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};