import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import store from './redux/store';
import 'antd/dist/antd.css';
import SignIn from './pages/SignIn';
import history from "./history";
import PrivateRouter from "./hoc/PrivateRouter";
import { isAuthenticated } from './utils';
import reportWebVitals from './reportWebVitals';
import './assets/sass/app.scss';
import SignUp from './pages/SignUp'
import ResetPassword from './pages/ResetPassword';
import ErrorFallback from "./ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import Loader from "./component/Loader";


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<Loader className={'content-loader'}/>}>
        <Switch>
          <App history={history}>
            <Switch>
            <Route
                exact
                path="/reset-password"
                render={props =>
                  !isAuthenticated() ? <>
                    <Helmet>
                      <title>{"Haploscope Clinic"}</title>
                    </Helmet>
                    <ResetPassword {...props} />
                  </> :
                    <Redirect to={{ pathname: '/physicians', user: isAuthenticated, state: { from: props.location } }} />
                }
              />
              <Route
                exact
                path="/signup"
                render={props =>
                  !isAuthenticated() ? <>
                    <Helmet>
                          <title>{"Haploscope Clinic"}</title>
                    </Helmet>
                    <SignUp {...props} />
                  </> :
                    <Redirect to={{ pathname: '/physicians', user: isAuthenticated, state: { from: props.location } }} />
                }
              />
              <Route
                exact
                path="/"
                render={props =>
                  !isAuthenticated() ? <>
                    <Helmet>
                      <title>{"Haploscope Clinic"}</title>
                    </Helmet>
                    <SignIn {...props} />
                  </> :
                    <Redirect to={{ pathname: '/physicians', user: isAuthenticated, state: { from: props.location } }} />
                }
              />
            </Switch>
            <Switch>
              <PrivateRouter />
            </Switch>
          </App>
        </Switch>
              </Suspense>
          </ErrorBoundary>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
