import {
    GET_PHYSICIANS_LIST,
    DELETE_PHYSICIAN_LIST,
    UPDATE_STATUS_PHYSICIAN_LIST,
    GET_PHYSICIAN_DETAIL,
    EDIT_PHYSICIAN_DETAIL,
    UPDATE_PHYSICIAN_DETAIL,
    SEARCH_PHYSICIAN,
    UPDATE_PHYSICIAN_CLINIC_DETAIL,
    UPDATE_STATUS_PHYSICIAN_CLINIC,
    DELETE_PHYSICIAN_CLINIC,
    GET_PHYSICIAN_PATIENTS,
    SEARCH_PHYSICIAN_PATIENTS,
    ADD_PHYSICIAN_MINUTES,
    ADD_PHYSICIAN
} from "../../actionTypes";
import { jsonToFormData } from "../../../utils";


export const getPhysicianList = () => async (dispatch, getState, api) => {
    return await api
        .get("/clinic/getPhysicians")
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_PHYSICIANS_LIST,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

//deletePhysician
export const deletePhysicianList = (payload, type) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/deletePhysician", payload)
        .then((res) => {
            if (res.status === 201) {
                if (type === "clinic") {
                    dispatch({
                        type: DELETE_PHYSICIAN_CLINIC,
                        payload: payload,
                    })
                } else {
                    dispatch({
                        type: DELETE_PHYSICIAN_LIST,
                        payload: payload,
                    })
                }
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
export const updatePhysicianStatusList = (payload, type) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/updatePhysicianStatus", payload)
        .then((res) => {
            if (res.status === 200 && type === 'clinic') {
                dispatch({
                    type: UPDATE_STATUS_PHYSICIAN_CLINIC,
                    payload: payload,
                })
            } else {
                dispatch({
                    type: UPDATE_STATUS_PHYSICIAN_LIST,
                    payload: payload,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export const resendInvitePhysician = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/resendInvitationPhysician", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}


export const PhysicianDetailData = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/getPhysicianDetails", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_PHYSICIAN_DETAIL,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

///clinic/editPhysician
export const physicianEditDetail = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/editPhysician", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: EDIT_PHYSICIAN_DETAIL,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
//updatePhysicianDetail
export const updatePhysicianDetail = (payload, type) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/updatePhysician", jsonToFormData(payload))
        .then((res) => {
            if (res.status === 201 && type === 'clinic') {
                dispatch({
                    type: UPDATE_PHYSICIAN_CLINIC_DETAIL,
                    payload: res.data.data,
                })
            } else {
                let data = {
                    result: res.data.data,
                    type: type
                }
                dispatch({
                    type: UPDATE_PHYSICIAN_DETAIL,
                    payload: data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
export const searchPhysicianList = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/search-physicians", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: SEARCH_PHYSICIAN,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}


export const getPhysicianPatients = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/getPhysicianPatients", payload)
        .then((res) => {
            dispatch({
                type: GET_PHYSICIAN_PATIENTS,
                payload: res.data.data,
            })
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};
export const filterSearch = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/searchPhysicianPatients", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: SEARCH_PHYSICIAN_PATIENTS,
                    payload: res.data.data.patients,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
///clinic/assignPatients
export const assignPatientsData = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/assignPatients", payload)
        .then((res) => {
            if (res.status === 201) {
                dispatch({
                    type: GET_PHYSICIAN_PATIENTS,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};
///clinic/addMinutesPhysician
export const addMinutesPhysician = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/addMinutesPhysician", payload)
        .then((res) => {
            if (res.status === 201) {
                dispatch({
                    type: ADD_PHYSICIAN_MINUTES,
                    payload: payload,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};

export const addPhysician = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/addPhysician", jsonToFormData(payload))
        .then((res) => {
            if (res.status === 201) {
                dispatch({
                    type: ADD_PHYSICIAN,
                    payload: res.data.data.data,
                })

            }

            return res;
        })
        .catch((err) => {

            return err.response;
        });
};