import React, { useEffect, useState } from "react";
import moment from "moment";
import { getCurrentBilling } from "../../../redux/invoice/action";
import { useDispatch, useSelector } from "react-redux";
import { getStateData } from "../../../redux/clinic/action";
import Loader from "../../Loader";

const AdditionalMinutes = ({ modalVal ,loaderdata}) => {
    const dispatch = useDispatch(null)
    const billingCycle = useSelector(state => state?.invoiceReducer?.currentBillingCycle)
    const country = useSelector(state => state?.clinicReducer?.countryData)
    const [loading, setLoading] = useState(false)
    const [billing, setBilling] = useState({})
    useEffect(() => {
        setLoading(true)
        // dispatch(getCurrentBilling()).then(res => {
        //     if (res.status === 200) {
        //         setLoading(false)
        //     } else {
        //         setLoading(false)
        //     }
        // })
    }, [])
    useEffect(() => {
        if (country.length > 0) {
            dispatch(getStateData({ country_id: billingCycle?.billing_address?.country })).then((res) => {
                if (res.status === 200) {
                    res.data.data.states?.forEach(ele => {
                        if (ele.id === billingCycle?.billing_address?.state) {
                            setBilling(ele)
                        }
                    })

                }
            })
        }
    }, [country.length, billingCycle])

    return (
        <div className="main_additionalmin-block">
            <h2>current billing cycle</h2>
            {
                loaderdata ?
                    <Loader className={'content-loader'} />
                    :
                    <div className="sub_additionalmin-block">
                        <div className="client_corporation_block">
                            <h6>{billingCycle?.clinic_name}</h6>
                            {
                                country?.map(item => {
                                    return (
                                        item.id === billingCycle?.billing_address?.country ?
                                            <span className="address" key={item.id}>{billingCycle?.billing_address?.street}, <br></br> {billingCycle?.billing_address?.city}, {billing?.name} {item.name} {billingCycle?.billing_address?.zipcode}</span>
                                            :
                                            ""
                                    )
                                }
                                )
                            }
                            <div className="physician_licenses-block physician_block-top">
                                <span className="title">Total Physician Licenses: {billingCycle?.noOfPhysician}</span>
                                {/* <span className="number"></span> */}
                            </div>
                            <div className="physician_licenses-block">
                                <span className="title">Monthly Minutes Plan: {billingCycle?.plan} min</span>
                                {/* <span className="number">{billingCycle?.planCost}</span> */}
                            </div>
                            <div className="billing_cycle-block">
                                <h4>Billing Cycle: {moment.utc(billingCycle?.startDate).local().format('MM/DD/YYYY')} - {moment.utc(billingCycle?.endDate).local().format('MM/DD/YYYY')}</h4>
                                <div className="total_plan total">
                                    <span>Total Plan Cost for {billingCycle?.noOfPhysician} licenses</span>
                                    <span>${new Intl.NumberFormat('US', { maximumSignificantDigits: 3 }).format(billingCycle?.totalPlanCost || 0)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="table_responsive">
                            <table className="table additionalmin_table">
                                <thead>
                                    <tr>
                                        <td>Additional Minutes </td>
                                        <td>Purchase Date</td>
                                        <td>Purchased Minutes</td>
                                        <td>Cost</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        billingCycle?.additionMin?.length > 0 &&
                                        billingCycle?.additionMin?.map((cost, i) => {
                                            return (
                                                <tr>
                                                    <td><span>Physician - {cost?.physicianName}</span></td>
                                                    <td><span> {moment.utc(cost?.purchesedDate).local().format('MM/DD/YYYY')}</span></td>
                                                    <td><span>{cost?.purchesedMin}</span></td>
                                                    <td><span>${cost?.cost}</span></td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {/* <tr>
                                        <td><span>Physician - John Croft</span></td>
                                        <td><span>20-01-2022</span></td>
                                        <td><span>300</span></td>
                                        <td><span>$300</span></td>
                                    </tr>
                                    <tr>
                                        <td><span>Physician - John Croft</span></td>
                                        <td><span>20-01-2022</span></td>
                                        <td><span>300</span></td>
                                        <td><span>$300</span></td>
                                    </tr>
                                    <tr>
                                        <td><span>Physician - Amanda Nancy</span></td>
                                        <td><span>20-01-2022</span></td>
                                        <td><span>300</span></td>
                                        <td><span>$300</span></td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>

                        <div className="total_cost total">
                            <div className="sub_total-cost">
                                <span>Total Cost</span>
                                <span>${new Intl.NumberFormat('US', { maximumSignificantDigits: 3 }).format(billingCycle?.totalCost || 0)}</span>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}
export default AdditionalMinutes