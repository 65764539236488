import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import { deletePhysicianList } from "../../../redux/physicians/action";
import FilledButton from "../../FilledButton";
import { useForm } from "react-hook-form";


const DeletePhysicianPop = ({ modalVal, handleOpenModal }) => {
    const { handleSubmit } = useForm({ mode: "all" });
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const deletePhysicianHandler = () => {
        let payload = {
            id: modalVal.id
        }
        setLoading(true)
        dispatch(deletePhysicianList(payload, modalVal.type)).then(res => {
            if (res.status === 201) {
                setLoading(false)
                handleOpenModal('CommonPop', { header: "Success", body: res.data.message, auth: true })
            }
            else {
                setLoading(false)
                handleOpenModal('CommonPop', { header: "Error", body: res.data.message, auth: true })
            }
        }).catch(e => {
            setLoading(false)
            handleOpenModal('CommonPop', { header: "Error", body: e.message, auth: true })
        })
    }
    return (
        <div className='main_delete_modal'>
            <h4>Delete a Physician?</h4>
            <div className='delete_content'>
                {
                    modalVal.status ?
                        <p>You are about to delete a physician. This will
                            remove all his/her assigned patients
                            from the database. You may transfer the patients
                            to another physician before this action. </p>
                        :
                        <p>You are about to delete a physician whose account
                            activation is still pending. Once deleted, this
                            physician will not be able to activate his/her account. </p>
                }

            </div>
            <span className='reversed'>Deleting a physician can’t be reversed!</span>
            <span className='proceed'>Do you want to proceed?</span>

            <form onSubmit={handleSubmit(deletePhysicianHandler)} className="form_group d_flex justify_content_center form_action delete_modal_btn">
                <FilledButton type="submit" loading={loading} value={'Yes'} className="btn btn_default" loader_class={'btn_loader_red'} />
                <button className="btn btn_primary" type={'reset'} onClick={() => handleOpenModal()}>No</button>

            </form>
        </div>
    )
}
export default DeletePhysicianPop
