export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const RESEND_SUCCESS = "RESEND_SUCCESS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

//physician List
export const GET_PHYSICIANS_LIST = "GET_PHYSICIANS_LIST";
export const DELETE_PHYSICIAN_LIST = "DELETE_PHYSICIAN_LIST";
export const UPDATE_STATUS_PHYSICIAN_LIST = "UPDATE_STATUS_PHYSICIAN_LIST";
export const GET_PHYSICIAN_DETAIL = "GET_PHYSICIAN_DETAIL";
export const EDIT_PHYSICIAN_DETAIL = "EDIT_PHYSICIAN_DETAIL";
export const UPDATE_PHYSICIAN_DETAIL = "UPDATE_PHYSICIAN_DETAIL";
export const SEARCH_PHYSICIAN = "SEARCH_PHYSICIAN";
export const GET_PHYSICIAN_PATIENTS = "GET_PHYSICIAN_PATIENTS";
export const SEARCH_PHYSICIAN_PATIENTS = "SEARCH_PHYSICIAN_PATIENTS";
export const ADD_PHYSICIAN_MINUTES = "ADD_PHYSICIAN_MINUTES";
export const ADD_PHYSICIAN = "ADD_PHYSICIAN";

//patients
export const GET_PATIENTS_LIST = "GET_PATIENTS_LIST";
export const ADD_PATIENTS_LIST = "ADD_PATIENTS_LIST";
export const GET_PATIENTS_DETAIL = "GET_PATIENTS_DETAIL";
export const DELETE_PATIENTS_LIST = "DELETE_PATIENTS_LIST";
export const EDIT_PATIENTS_DETAIL = "EDIT_PATIENTS_DETAIL";
export const UPDATE_PATIENTS_DETAIL = "UPDATE_PATIENTS_DETAIL";
export const SEARCH_PATIENTS = "SEARCH_PATIENTS";
export const BULK_UPLOAD_PATIENT = "BULK_UPLOAD_PATIENT";
export const UPDATE_PATIENTS_LIST_STATUS = "UPDATE_PATIENTS_LIST_STATUS";
export const UPDATE_CLINIC_MINUTE_PLAN = "UPDATE_CLINIC_MINUTE_PLAN";
export const CANCEL_CLINIC_MINUTE_PLAN = "CANCEL_CLINIC_MINUTE_PLAN";
export const GET_CAREGIVERS_DATA = "GET_CAREGIVERS_DATA";

//clinic

export const GET_CLINIC_DETAILS = "GET_CLINIC_DETAILS";
export const ADD_PHYSICIAN_CLINIC = "ADD_PHYSICIAN_CLINIC";
export const UPDATE_PHYSICIAN_CLINIC_DETAIL = "UPDATE_PHYSICIAN_CLINIC_DETAIL";
export const UPDATE_STATUS_PHYSICIAN_CLINIC = "UPDATE_STATUS_PHYSICIAN_CLINIC";
export const DELETE_PHYSICIAN_CLINIC = "DELETE_PHYSICIAN_CLINIC";

export const EDIT_AUTH_USER = "EDIT_AUTH_USER";
export const ADD_AUTHORIZED = "ADD_AUTHORIZED";
export const UPDATE_AUTH_USER = "UPDATE_AUTH_USER";
export const UPDATE_AUTH_USER_STATUS = "UPDATE_AUTH_USER_STATUS";
export const DELETE_AUTH_USER = "DELETE_AUTH_USER";
export const CHANGE_PRIMARY_AUTH_USER = "CHANGE_PRIMARY_AUTH_USER";
export const GET_COUNTRY_DATA = "GET_COUNTRY_DATA";
export const GET_STATE_DATA = "GET_STATE_DATA";
export const EDIT_CLINIC_DETAILS = "EDIT_CLINIC_DETAILS";
export const UPDATE_CLINIC = "UPDATE_CLINIC";
export const CLEAR_CLINIC = "CLEAR_CLINIC";

export const GET_CLINIC_PROFILE = "GET_CLINIC_PROFILE";
export const GET_MINUTE_DATA = "GET_MINUTE_DATA";


//invoice
export const GET_INVOICE_DATA = "GET_INVOICE_DATA";
export const GET_CURRENT_BILLING_DATA = "GET_CURRENT_BILLING_DATA";

//schedule GET_ALL_PATIENT_SCHEDULE_LIST

export const GET_SCHEDULE_PATIENT_DETAILS = "GET_SCHEDULE_PATIENT_DETAILS";
export const GET_SCHEDULE_LIST = "GET_SCHEDULE_LIST";
export const SET_FILTER_SCHEDULE = "SET_FILTER_SCHEDULE";
export const GET_AVAILABLE_MINUTE = "GET_AVAILABLE_MINUTE";
export const GET_PATIENT_SCHEDULE_BY_DATE = "GET_PATIENT_SCHEDULE_BY_DATE";
export const GET_ALL_PATIENT_SCHEDULE_LIST = "GET_ALL_PATIENT_SCHEDULE_LIST";
export const GET_SCHEDULE_STATUS = "GET_SCHEDULE_STATUS";
export const DELETE_APPOINTMENT_LIST = "DELETE_APPOINTMENT_LIST";
export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const EDIT_APPOINTMENT = "EDIT_APPOINTMENT";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const GET_PHYSICIAN_APPOINTMENT = "GET_PHYSICIAN_APPOINTMENT"

