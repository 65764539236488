import ReactCodeInput from 'react-verification-code-input';
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import history from "../../../history"
import { reSendOTP, verifyOTP } from "../../../redux/auth/action";
import FilledButton from '../../FilledButton';


const VerificationCodePop = ({ email, counter_time }) => {
    const dispatch = useDispatch();
    const [valueOTP, setValueOTP] = useState([]);
    const [oTPError, setOTPError] = useState(false)
    const [error, setError] = useState('')
    const [counter, setCounter] = useState(0)
    const [loading, setLoading] = useState(false)
    const ref = useRef()

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer)
    }, [counter])
    const resetOtp = () => {
        dispatch(reSendOTP({ email: email })).then(res => {
            if (res.status === 200) {
                setCounter(30)
            }
        })
        setOTPError(false)
        ref.current.state.values = Array(5).fill('')
        setValueOTP([])
    }
    const clearValue = () => {
        setOTPError(false)
        ref.current.state.values = Array(5).fill('')
        setValueOTP([])
    };
    const AuthenticationVaried = (e) => {
        e.preventDefault();
        setLoading(true)
        let payload = {
            email: email,
            otp: valueOTP
        }
        if (valueOTP.length !== 5) {
            setError('Please Enter OTP')
            setLoading(false)
        } else {
            setError(false)
            dispatch(verifyOTP(payload)).then(res => {
                if (res.status === 200) {
                    history.push('/physicians')
                    setLoading(false)
                } else {
                    setLoading(false)
                    setOTPError(true)
                }
            }).catch(e => {
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        if (counter_time > 0) {
            setCounter(30)
        }
    }, [])

    const onPinComplete = code => {
        setError('')
    };

    return (
        <>
            {/* 2. Authenticate your email */}
            <div className="custom_modal text_center">
                <h3 className='mb_5'>Authenticate your email</h3>
                <div className="otpMsg">
                    <form onSubmit={(e) => AuthenticationVaried(e)}>
                        {
                            oTPError ?
                                <p className='text_red otp_error-msg'>This 5-digit code has either expired or not found in our database. Please click below to resend code</p>
                                :
                                <p className='notice'>Please enter the 5-digit code sent to your email for account verification</p>
                        }

                        <div className="form_row mt_2">
                            <div className={'verification_otp'}>
                                <ReactCodeInput
                                    ref={ref}
                                    fields={5}
                                    passwordMask={false}
                                    placeholder={false}
                                    autoFocus={true}
                                    onChange={value => setValueOTP(value)}
                                    onComplete={onPinComplete}
                                    values={valueOTP}
                                />
                            </div>
                        </div>
                        <div className={'reset_time_count_block'}>
                            <p onClick={() => resetOtp()} className={counter > !0 ? "disabled resend_color" : "resendcode_msg"}  >Resend Code</p>
                            {counter > 0 &&
                                <div className='text_right'>
                                    {`00:${counter}`}
                                </div>
                            }
                        </div>
                        <div className={'errorMsg'}>{
                            error
                        }</div>
                        <div className="form_group d_flex justify_content_center form_action resendcode_btn">
                            <button className='btn btn_default' type={'reset'} onClick={() => clearValue()}>Clear</button>
                            <FilledButton type="submit" loading={loading} value={'Submit'} className="btn_primary ml_2" />
                        </div>
                    </form>
                </div>
            </div>
            {/* |END| Authenticate your email */}
        </>
    )
}
export default VerificationCodePop