import {
    ADD_PHYSICIAN_CLINIC, DELETE_PHYSICIAN_CLINIC,
    GET_CLINIC_DETAILS,
    UPDATE_PHYSICIAN_CLINIC_DETAIL, UPDATE_STATUS_PHYSICIAN_CLINIC,
    ADD_AUTHORIZED, EDIT_AUTH_USER, UPDATE_AUTH_USER, DELETE_AUTH_USER,
    UPDATE_AUTH_USER_STATUS, CHANGE_PRIMARY_AUTH_USER, GET_COUNTRY_DATA, GET_STATE_DATA, EDIT_CLINIC_DETAILS, UPDATE_CLINIC,
    CLEAR_CLINIC, GET_CLINIC_PROFILE,GET_MINUTE_DATA,UPDATE_CLINIC_MINUTE_PLAN,CANCEL_CLINIC_MINUTE_PLAN
} from "../../actionTypes";

let initialState = {
    clinic_detail: {},
    edit_Auth_user: {},
    countryData: [],
    stateData: [],
    editClinicData: {},
    getMinute:[],
    minute_Text:""
}

export const ClinicReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case GET_CLINIC_DETAILS: {
            let result = payload?.Data?.reduce((acc, cur) => ({ ...cur, ...acc }))
            let PrimaryUser = result?.PrimaryUser?.reduce((acc, cur) => ({ ...cur, ...acc }))
            let billing_address = result?.billing_address?.reduce((acc, cur) => ({ ...cur, ...acc }))
            let business_address = result?.business_address?.reduce((acc, cur) => ({ ...cur, ...acc }))
            return {
                ...state,
                clinic_detail: { ...result, PrimaryUser: { ...PrimaryUser }, billing_address: { ...billing_address }, business_address: { ...business_address } }
            }
        }
        case ADD_PHYSICIAN_CLINIC: {
            let temp = { ...state }
            let result = [...state.clinic_detail.physicianUsers, { ...payload.physician, physician_id: payload.physician._id }];
            temp.clinic_detail.PhysicianCount = temp.clinic_detail.PhysicianCount + 1
            return {
                ...state,
                clinic_detail: { ...temp.clinic_detail, physicianUsers: [...result] }
            }
        }
        case UPDATE_PHYSICIAN_CLINIC_DETAIL: {
            let temp = { ...state };
            let index = state.clinic_detail.physicianUsers.findIndex(data => data.physician_id === payload?.physician._id);
            if (index > -1) {
                temp.clinic_detail.physicianUsers[index] = { ...temp.clinic_detail.physicianUsers[index], ...payload.physician }
            }
            return temp
        }
        case UPDATE_STATUS_PHYSICIAN_CLINIC: {
            let temp = { ...state }
            let index = state.clinic_detail.physicianUsers.findIndex(data => data.physician_id === payload.id)
            if (index > -1) {
                temp.clinic_detail.physicianUsers[index].isActive[0].isActive = payload.status
            }
            return temp
        }
        case DELETE_PHYSICIAN_CLINIC: {
            let temp = { ...state }
            let result = state.clinic_detail.physicianUsers.filter(item => item.physician_id !== payload.id)
            temp.clinic_detail.PhysicianCount = temp.clinic_detail.PhysicianCount - 1
            return {
                ...state,
                clinic_detail: { ...temp.clinic_detail, physicianUsers: [...result] }
            }
        }
        case ADD_AUTHORIZED: {
            let temp = { ...state };
            let result = [...state.clinic_detail.authUsers, { ...payload.user, user_id: payload.user._id }];
            temp.clinic_detail.AuthUserCount = temp.clinic_detail.AuthUserCount + 1
            return {
                ...state,
                clinic_detail: { ...temp.clinic_detail, authUsers: [...result] },
            }
        }

        case EDIT_AUTH_USER: {
            return {
                ...state,
                edit_Auth_user: payload
            }
        }
        case UPDATE_AUTH_USER: {
            let temp = { ...state };
            let index = state.clinic_detail.authUsers.findIndex(data => data.user_id === payload.contact.user_id);
            if (index > -1) {
                temp.clinic_detail.authUsers[index] = { ...temp.clinic_detail.authUsers[index], ...payload.contact }
            }
            return temp
        }
        case DELETE_AUTH_USER: {
            let temp = { ...state }
            let result = state.clinic_detail.authUsers.filter(item => item.user_id !== payload.id)
            temp.clinic_detail.AuthUserCount = temp.clinic_detail.AuthUserCount - 1
            return {
                ...state,
                clinic_detail: { ...temp.clinic_detail, authUsers: [...result] }
            }
        }
        case UPDATE_AUTH_USER_STATUS: {
            let temp = { ...state };
            let index = state.clinic_detail.authUsers.findIndex(data => data.user_id === payload.id);
            if (index > -1) {
                temp.clinic_detail.authUsers[index].isActive[0].isActive = payload.status
            }
            return temp
        }
        case CHANGE_PRIMARY_AUTH_USER: {
            let temp = { ...state };
            let index = temp.clinic_detail.authUsers.findIndex(data => data.user_id === payload.user_id);
            let prevIndex = temp.clinic_detail.authUsers.findIndex(data => data.isPrimary);
            if (index > -1) {
                temp.clinic_detail.PrimaryUser = { ...temp.clinic_detail.authUsers[index] };
                temp.clinic_detail.authUsers[index] = { ...state.clinic_detail.authUsers[index], isPrimary: true };
                if (prevIndex > -1) {
                    temp.clinic_detail.authUsers[prevIndex] = { ...state.clinic_detail.authUsers[prevIndex], isPrimary: false };
                }
            }
            return { ...temp }
        }
        case GET_COUNTRY_DATA: {
            return {
                ...state,
                countryData: payload
            }
        }

        case GET_STATE_DATA: {
            return {
                ...state,
                stateData: payload
            }

        }
        case EDIT_CLINIC_DETAILS: {
            let result = payload?.reduce((acc, cur) => ({ ...cur, ...acc }))
            return {
                ...state,
                editClinicData: result
            }
        }
        case UPDATE_CLINIC: {
            let temp = { ...state }
            temp.clinic_detail.clinic_name = payload.clinic_name;
            return {
                ...state,
                clinic_detail: { ...temp.clinic_detail, billing_address: { ...payload.billing_address }, business_address: { ...payload.business_address } }
            }
        }
        case CLEAR_CLINIC: {
            return {
                ...state,
                getClinicData: {}
            }
        }
        case GET_MINUTE_DATA:{
            return {
                ...state,
                getMinute: payload
            }
        }

        case UPDATE_CLINIC_MINUTE_PLAN:{
            let temp = {...state};
            temp.clinic_detail.upcoming_clinic_billing_minute = payload
            return temp
        }
        case CANCEL_CLINIC_MINUTE_PLAN:{
            let temp = {...state};
            temp.clinic_detail.upcoming_clinic_billing_minute = payload
            return temp
        }
        default:
            return state
    }
}

