import React from "react";

const AccountcredentialsPop = ({ handleOpenModal }) => {
    return (
        <div className='main_delete_modal'>
            <h4>Invalid Account credentials</h4>
            <div className='delete_content'>
                <p>We are unable to locate an account with the credentials provided. Please try again or click on Forgot Password to reset password. </p>
            </div>
            <div className="form_group d_flex justify_content_center form_action delete_modal_btn">
                <button className="btn btn_default" onClick={() => handleOpenModal()}>Clear</button>
                <button className="btn btn_primary" onClick={() => handleOpenModal('ForgotPasswordPop')}>Next</button>
               
            </div>
        </div>
    )
}
export default AccountcredentialsPop