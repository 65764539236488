import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/new_hp_logo.svg';
import Footer from "../../layout/Footer";
import { Form, Input, Button, Checkbox } from 'antd';
import PopComponent from "../../hoc/PopContent";
import CustomModal from '../../hoc/CustomModal';
import { userSignup } from "../../redux/auth/action";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import history from "../../history";
import FilledButton from '../../component/FilledButton';

var CryptoJS = require("crypto-js");



const SignUp = () => {
    const { search } = useLocation();
    const [formRef, setFormRef] = useState(null);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [openModal, setModalOpen] = useState(false);
    const [modalVal, setModalVal] = useState('')
    const [counter_time, setcounterTime] = useState(0)
    const [modalName, setModalName] = useState('')
    let ModalData = PopComponent[modalName]
    const dispatch = useDispatch();
    let decryptedData;
    let decrypted = CryptoJS.AES.decrypt(search?.substring(1)?.toString(), process.env.REACT_APP_SECRET_KEY);
    if (search) {
        decryptedData = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } else {
        history.push("/")
    }
    const handleChangePassword = (value) => {
        setLoading(true)
        Object.assign(value, { email: decryptedData?.email })
        dispatch(userSignup(value)).then(res => {
            if (res.status === 200) {
                setLoading(false)
                setcounterTime(30)
                setTimeout(() => {
                    setcounterTime(0)
                }, 3000)
                handleOpenModal('VerificationCodePop')
            } else {
                setLoading(false)
                setcounterTime(0)
                handleOpenModal('CommonPop', { header: "Info", body: res.data.message, path: "/" })
            }
        })
    }
    useEffect(() => {
        setFormRef(form)
    }, [])
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    };
    const handleOpenModal = (type, data) => {
        switch (type) {
            case "VerificationCodePop": {
                setModalVal(data)
                setModalName(type);
                setModalOpen(true)
            }
                break;
            case 'CommonPop': {
                setModalVal(data)
                setModalName(type);
                setModalOpen(true)
                break
            }
            default: {
                setModalOpen(!openModal);
            }
        }
    }
    const handleCloseModal = () => {
        setFormData({})
        form.resetFields();
    }
    return (
        <>
            <div className="signin">
                <div className="container">
                    <div className="row header_logo">
                        <div className="logo"><img src={logo} alt="logo_img" /></div>
                    </div>
                </div>
                <section className="sigin_banner signup_banner_block">
                    <div className="container">
                        <div className="sign_box signup_block">
                            <div className="account_system">
                                <h4>Account Management System</h4>
                            </div>
                            <div className="admin_sigin signup_clinic">
                                <h2>Sign Up</h2>
                                <Form
                                    form={form}
                                    onFinish={handleChangePassword}
                                    layout="vertical"
                                    autoComplete="off"
                                    initialValues={formData}
                                    onReset={() => handleCloseModal()}
                                >
                                    <div className="form_group">
                                        <div><label>Email</label></div>
                                        <input type="text" placeholder="Email" value={decryptedData?.email} className="form_control disabled" name="email" autoComplete="off" />

                                    </div>
                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        onChange={(e) => handleChange(e)}
                                        normalize={(val) => val?.replace(/\s/g, "") || ""}
                                        rules={[
                                            { required: true, message: 'Please input your New password!' },
                                            {
                                                pattern: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                                message: "Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit."
                                            },
                                            {
                                                maxLength: 8,
                                                message: "Password should be at-least 8 characters.",
                                            }
                                        ]}
                                    >
                                        <Input.Password
                                            name="password"
                                            onChange={(e) => handleChange(e)} placeholder={'Password'} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        onChange={(e) => handleChange(e)}
                                        normalize={(val) => val?.replace(/\s/g, "") || ""}
                                        rules={!formData.confirmPassword
                                            ? [{ required: true, message: 'Please input your Confirm password!' }]
                                            : [{
                                                validator: (_, value) => {
                                                    if (formData.password !== value) {
                                                        return Promise.reject('Confirm password is not match with new password');
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            }]}
                                    >
                                        <Input.Password name="confirmPassword" placeholder={'Password'} onChange={handleChange} />
                                    </Form.Item>
                                    <div className="d_flex_center mt_4 mb_4 form_buttons">
                                        <button type="reset" className="btn_default" onClick={() => handleCloseModal()} >Clear</button>
                                        <FilledButton type="submit" loading={loading} value={'Submit'} className="btn_primary ml_2" />
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
            <CustomModal className={"modal resendcode_popup"} modalName={modalName} modalIsOpen={openModal} handleOpenModal={handleOpenModal}>
                <ModalData handleOpenModal={handleOpenModal} openModal={openModal} modalVal={modalVal} email={decryptedData?.email} counter_time={counter_time} />
            </CustomModal>
        </>
    )
}

export default SignUp
