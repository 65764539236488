import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addMinutesPhysician } from "../../../redux/physicians/action";
import FilledButton from "../../FilledButton";
import React, { useState } from "react";

const AddMinutePop = ({ modalVal, handleOpenModal }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch(null)
    const { register, watch, formState: { errors }, handleSubmit, setValue, reset } = useForm({ mode: "all" });
    const minuteHandler = (state) => {

        let intMinutes = parseInt(state?.minutes)
        let payload = {
            pysician_id: modalVal.id,
            minutes: intMinutes,
        }
        setLoading(true)
        dispatch(addMinutesPhysician(payload)).then(res => {
            if (res.status === 201) {
                handleOpenModal('CommonPop', { header: "Success", body: res.data.message, auth: true })
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
    }
    return (
        <div className="custom_modal">
            <h3 className="form_heading text_center">Add Minutes</h3>
            <div className="form">
                <form onSubmit={handleSubmit(minuteHandler)}>
                    <div className="form_group">
                        <div className="form_group">
                            <label htmlFor="">
                                <h5>Minute</h5></label>
                            <span className={`fill valid`}>
                                <input type="text" className="form_control disabled" name="minutes" id="" value={modalVal?.minute} {...register('minutes')} />
                            </span>
                        </div>
                        <div className="form_group  form_action mt_5">
                            <button className='btn btn_default' type='reset' onClick={() => handleOpenModal()} >Cancel</button>
                            <FilledButton type="submit" loading={loading} value={'Submit'} className="btn_primary ml_2" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default AddMinutePop