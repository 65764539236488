import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {
    addAppointment,
    getAllPatientsForSchedule,
    getAvailableMinuteForSchedule,
    updateAppointment
} from "../../../redux/Schedule/action";
import moment from "moment";
import DatePicker from "react-datepicker";
import Validation from "../../Validation";
import {currentTime, generateAvatar} from "../../../utils";
import FilledButton from "../../FilledButton";

const EditAddAppointmentPop = ({handleOpenModal,modalVal}) =>{
    const dispatch = useDispatch();
    const patient_data = useSelector(state => state.scheduleReducer.patient_data)
    const editAppointment = useSelector(state => state.scheduleReducer.editAppointment )
    const { availableMinutes, isUnlimited } = useSelector(state => state.scheduleReducer.reqMinutes)
    const allPatients = useSelector(state => state.scheduleReducer.patients)
    const [loading, setLoading] = useState(false)
    const { register, watch, formState: { errors }, handleSubmit, setValue, reset,control ,getValues} = useForm({ mode: "all", defaultValues: { date: null } });
    const [appointmentState, setAppointmentState] = useState({
        physician_id:patient_data?.physician?._id,
        patient_id: null,
        date: null,
        startAt: null,
        callLength: '45',
        note: '',
        caregivers: []
    })

    useEffect(()=>{
         setValue('date', new Date(editAppointment?.date)  )
        setValue('startAt',  moment.utc(editAppointment?.date)._d  )
        setValue('callLength',  editAppointment?.callLength  )
        setValue('note', editAppointment?.note )
        setAppointmentState({
            ...appointmentState,
            date:  new Date(editAppointment?.date),
            callLength: String(editAppointment?.callLength),
            note: editAppointment?.note,
            patient_id: editAppointment?.patient_id?._id,
            startAt: moment.utc(editAppointment?.date)._d
        })
    },[editAppointment])

    useEffect(()=>{
        getAvailableMinute()
        getAllPatientData();
    },[])

    const getAvailableMinute =() =>{
        dispatch(getAvailableMinuteForSchedule({physician_id:patient_data?.physician?.[0]?._id}))
    }
    const getAllPatientData = ()=>{
        let payload = {
            physician_id:patient_data?.physician?.[0]?._id,
            name: " ",
            status: true,
            page: 0,
            limit: 0
        }
        dispatch(getAllPatientsForSchedule(payload))
    }

    const editAppointmentHandler = (state)=>{
        let date = moment(moment(state.date).format('YYYY-MM-DD') + " " + moment(state.startAt).format('HH:mm:ss')).format();
        let startAt =  Object.assign({ hour: moment.utc(state?.startAt).hours(), min: moment.utc(state?.startAt).minutes(), sec: moment.utc(state?.startAt).seconds() })
        let payload={
            date: moment.utc(date).format(),
            callLength: Number(state?.callLength),
            physician_id:patient_data?.physician?.[0]?._id,
            schedule_id:editAppointment?._id,
            startAt,
            note:state?.note
        }
        setLoading(true)
        dispatch(updateAppointment(payload)).then(res => {
            if (res.status === 200) {
                setLoading(false)
                handleOpenModal('CommonPop', { header: "Success", body: res.data.message, auth: true })
            }
            else {
                setLoading(false)
                handleOpenModal('CommonPop', { header: "Error", body: res.data.message, auth: true })
            }
        }).catch(e => {
            setLoading(false)
            handleOpenModal('CommonPop', { header: "Error", body: e.message, auth: true })
        })
    }
    const handleCallLength = (e)=>{
        const { name, value } = e.target;
        setAppointmentState({
            ...appointmentState,
            [name]: value
        })
    }

    const handlePatientsSelect = (e)=>{
        const { name, value } = e.target;
        setAppointmentState({
            ...appointmentState,
            [name]: value
        })
    }
    const clearDataHandler = ()=>{
        reset();
        setAppointmentState({
            ...appointmentState,
            patient_id: null,
            date: null,
            startAt: null,
            callLength: '45',
            note: '',
        })
    }
    return (
        <div className='main_comman_modal appoinment-modal_block'  >
            <div className='main_addappoinment'>
                <h4>{"Edit Appointment"}</h4>
                <div className='sub_appoinment_block'>
                    <form className='addappoinment_block' onSubmit={handleSubmit(editAppointmentHandler)}>
                        {/* date and time */}
                        <div className="form_row appoinment_time">
                            <div className="form_group">
                                <label>Date</label>
                                <span className='date_block'>
                              <Controller
                                  name="date"
                                  control={control}
                                  rules={{ required: 'date is Required' }}
                                  render={({
                                               field: { onChange, onBlur, value, name, ref },
                                               fieldState: { invalid, isTouched, isDirty, error },
                                               formState,
                                           }) => (
                                      <DatePicker
                                          onChange={onChange}
                                          className={`form_control ${watch("date")}`}
                                          selected={value}
                                          dateFormat="MMM d, yyyy"
                                          minDate={new Date()}
                                          placeholderText="Select Date"
                                          dropdownMode="select"
                                      />
                                  )}
                                  defaultValue={''}
                              />

                                </span>
                                <div className="invalid-feedback pl-4 d-block errorMsg">
                                    <Validation errors={errors.date} message={errors?.date?.message} watch={watch("date")} />
                                </div>
                            </div>

                            <div className="form_group">
                                <label>Starts At</label>
                                <span className='time_block'>
                                         <Controller
                                             name="startAt"
                                             control={control}
                                             rules={{ required: 'Start At is Required' }}
                                             render={({
                                                          field: { onChange, onBlur, value, name, ref },
                                                          fieldState: { invalid, isTouched, isDirty, error },
                                                          formState,
                                                      }) => (
                                                 <DatePicker
                                                     className={`form_control ${watch('startAt')}`}
                                                     selected={value}
                                                     onChange={onChange}
                                                     showTimeSelect
                                                     showTimeSelectOnly
                                                     timeCaption="Start At"
                                                     dateFormat="h:mm aa"
                                                     timeIntervals={1}
                                                     minTime={moment(moment.utc(watch("date")).local()).isSame(moment(), 'day') ? new Date() : moment()}
                                                     maxTime={moment().endOf('days').toDate()}
                                                 />
                                             )}
                                             defaultValue={''}
                                         />
                                     <span className='pst'>{`${currentTime.format("z")}`}</span>
                                </span>

                                <div className="invalid-feedback pl-4 d-block errorMsg">
                                    <Validation errors={errors.startAt} message={errors?.startAt?.message} watch={watch("startAt")} />
                                </div>
                            </div>
                        </div>
                        {/* call length */}
                        <div className='main_call_length'>
                            <p>Call Length</p>
                            <div className='request_min_block min_block'>
                                <div className='radio_buttons_block'>
                                    <div className="min_block">
                                        <input type="radio" disabled={Number(availableMinutes) < 30 && !isUnlimited} name="callLength" value={30} checked={appointmentState.callLength === '30'} {...register('callLength',{
                                            onChange:(e) => handleCallLength(e)
                                        })} />
                                        <span className="check" />
                                        <label>30 min</label>
                                    </div>
                                    <div className="min_block">
                                        <input type="radio" disabled={Number(availableMinutes) < 45 && !isUnlimited}  name="callLength"  value={45} checked={availableMinutes < 45 && !isUnlimited ? false : appointmentState.callLength === '45'} {...register('callLength',{
                                                        onChange:(e) => handleCallLength(e)
                                        })} />
                                        <span className="check" />
                                        <label>45 min</label>
                                    </div>
                                </div>

                                {(Number(availableMinutes) > 0 || Number(availableMinutes) === 0) && !isUnlimited &&
                                <div className='available_min_block'>
                                    <span>(available minutes: {Math.floor(Number(availableMinutes))})</span>
                                </div>
                                }
                                {
                                    isUnlimited &&
                                    <div className='available_min_block'>
                                        <span>(available minutes: {'Unlimited'})</span>
                                    </div>
                                }

                            </div>
                            <div className="invalid-feedback pl-4 d-block errorMsg">

                            </div>
                        </div>
                        {/* note textarea */}
                        <div className='main_note_block form_group'>
                            <textarea className='form_control' name='note' placeholder='Note' {...register('note')}/>
                            <span>Optional</span>
                        </div>
                        {/* select Patient */}
                        <div className='select_patient_block'>
                            <h6>Select Patient</h6>
                            {





                                    <div className='main_cp_list' >
                                        <div className={'current_patient_box'}>
                                            <div className={'main_cp_list'}>
                                                <div className='main_cp_list_col' >
                                                    <div className='cp_profile_block'>
                                                        <div className='profile'>
                                                            <img src={(!editAppointment?.patient_id?.profile || editAppointment?.patient_id?.profile === null || editAppointment?.patient_id?.profile === "NA" || editAppointment?.patient_id?.profile === "") ? generateAvatar(editAppointment?.patient_id?.first_name + " " + editAppointment?.patient_id?.last_name) : editAppointment?.patient_id?.profile} alt="" />
                                                        </div>
                                                        <span className='cp_name'>{editAppointment?.patient_id?.first_name + " " + editAppointment?.patient_id?.last_name}</span>
                                                    </div>
                                                    <div className='cp_checkbox'>
                                                        <div className="form_group checkbox selectall_checkbox">
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) => handlePatientsSelect(e, editAppointment?.patient_id)}
                                                                checked={appointmentState.patient_id === editAppointment?.patient_id._id}
                                                            />
                                                            <span className="checkbox_clone" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            <Validation errors={errors.patient_id} message={errors?.patient_id?.message} watch={watch("patient_id")} />
                                        </div>
                                    </div>

                            }
                        </div>
                        <div className="form_group d_flex justify_content_center form_action appoinment_btn">
                            <button className="btn btn_default" type="reset" onClick={() => clearDataHandler()}>Clear</button>
                            <FilledButton type="submit" loading={loading} value={'Submit'} className="btn btn_primary"  />
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default EditAddAppointmentPop