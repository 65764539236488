import { GET_CLINIC_PROFILE, LOGIN_SUCCESS } from '../../actionTypes';
import { getAccessToken } from "../../../utils";


const initialState = {}

const currentUser = getAccessToken() ? JSON.parse(atob(getAccessToken().split('.')[1])) : '';



export default function loginReducer(state = initialState, action) {
    const { type } = action;
    switch (type) {
        case LOGIN_SUCCESS: {
            return { ...action.currentUser }
        }
        case GET_CLINIC_PROFILE: {
            return { ...action.currentUser }
        }
        default:
            return state;
    }

}