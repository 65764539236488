import {
    GET_SCHEDULE_PATIENT_DETAILS,
    GET_SCHEDULE_LIST,
    SET_FILTER_SCHEDULE,
    GET_AVAILABLE_MINUTE,
    GET_PATIENT_SCHEDULE_BY_DATE,
    GET_ALL_PATIENT_SCHEDULE_LIST,
    GET_SCHEDULE_STATUS,
    DELETE_APPOINTMENT_LIST,
    ADD_APPOINTMENT,
    EDIT_APPOINTMENT,
    UPDATE_APPOINTMENT,
    GET_PHYSICIAN_APPOINTMENT,
    CLEAR_CLINIC
} from "../../actionTypes";

export const schedulePatientData = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/getPatientData", payload)
        .then((res) => {
            if (res.status === 200) {
                    dispatch({
                        type: GET_SCHEDULE_PATIENT_DETAILS,
                        payload: res.data.data,
                    })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const clearPatientdata = (data) => ({
    type: GET_SCHEDULE_PATIENT_DETAILS,
    payload: data,
})

export const getAllScheduleList = (payload, type) => async (dispatch, getState, api) => {

    return await api
        .post("/clinic/getSchedule", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_SCHEDULE_LIST,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const getFilterStatus = (data) => ({
    type: SET_FILTER_SCHEDULE,
    payload: data.status,
})
///clinic/getAvailableMinuteForSchedule
export const getAvailableMinuteForSchedule = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/getAvailableMinuteForSchedule", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_AVAILABLE_MINUTE,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
///clinic/getPatientScheduleByDate

export const getPatientScheduleByDate = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/getPatientScheduleByDate", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_PATIENT_SCHEDULE_BY_DATE,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
///clinic/getAllPatientsForSchedule


export const clearPatientData = (data) => ({
    type: GET_ALL_PATIENT_SCHEDULE_LIST,
    payload: [],
})
export const getAllPatientsForSchedule = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/getAllPatientsForSchedule", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_ALL_PATIENT_SCHEDULE_LIST,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
//GET_SCHEDULE_STATUS_URL /clinic/getCalenderSchedule

    export const getCalenderSchedule = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/getCalenderSchedule", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_SCHEDULE_STATUS,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
export const deleteAppointment = (payload,type) => async (dispatch, getState, api) => {

    return await api
        .post("/clinic/deleteSchedule", payload)
        .then((res) => {

            if (res.status === 200) {
                Object.assign(payload,{type:type})
                dispatch({
                    type: DELETE_APPOINTMENT_LIST,
                    payload: payload,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const addAppointment = (payload,type) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/createSchedule", payload)
        .then((res) => {

            if (res.status === 200) {
                dispatch({
                    type: ADD_APPOINTMENT,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};


export const editAppointment = (payload) => ({
    type: EDIT_APPOINTMENT,
    payload: payload,
})

export const updateAppointment = (payload,id) => async (dispatch, getState, api) => {

    return await api
        .post("/clinic/updateSchedule", payload)
        .then((res) => {

            if (res.status === 200) {
                dispatch({
                    type: UPDATE_APPOINTMENT,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
export const addPhysicianAppointment = (payload) => async (dispatch, getState, api) => {

    return await api
        .get("/clinic/getPhysicianForSchedule")
        .then((res) => {

            if (res.status === 200) {
                dispatch({
                    type: GET_PHYSICIAN_APPOINTMENT,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
