import React, {useEffect, useState} from "react";
import profile_pic from '../../../assets/images/profile_pic.png'
import mobile_icon from '../../../assets/images/mobile-icon.svg'
import mail_icon from '../../../assets/images/mail-icon.svg'
import user_icon from '../../../assets/images/user-icon.svg'
import {useDispatch, useSelector} from "react-redux";
import {formatPhoneNumber, generateAvatar} from "../../../utils";
import {getCaregiversData} from "../../../redux/patients/action";
import Loader from "../../Loader";

const CaregiversPop = ({modalVal}) => {
    const [loader,setLoader]=useState(false)
    const caregiver = useSelector(state => state?.patientsReducer?.caregiver)
    const dispatch = useDispatch();
    useEffect(()=>{
        setLoader(true)
        dispatch(getCaregiversData({id:modalVal?.id})).then(res=>{
            if(res.status === 200){
                setLoader(false)
            }
        })
    },[modalVal])

    return (
        <div className="caregivers-main_block">
            <h5>Caregivers of {modalVal?.PatientName}</h5>
            {
               loader ?
                   <div className="caregivers-sub_block">
                        <Loader className={'content-loader'}/>
                   </div>
                    :
                    <div className="caregivers-sub_block">
                        {caregiver?.length > 0 ?
                            caregiver?.map((car,i)=>{
                                return(
                                    car?.isPrimary ?
                                        <div className={`caregivers-detail_block selected_caregiver`}>
                                            <div className="profile_pic"><img src={car?.profile ? car?.profile : generateAvatar(car?.first_name + " " + car?.last_name)} alt=''/></div>
                                            <div className="caregivers_detail">
                                                <h6>{car?.first_name + " " + car?.last_name}</h6>
                                                {
                                                    car?.phone &&
                                                    <span className="mobile_num comman_detail">
                                            <img src={mobile_icon} alt={'mobile_icon'}/>{formatPhoneNumber(car?.phone)}
                                        </span>
                                                }
                                                <span className="mail comman_detail"><img src={mail_icon} alt={'mail_icon'}/>{car?.email}</span>
                                                <span className="user_detail comman_detail"><img src={user_icon} alt={'user_icon'}/>{car?.relationship}</span>
                                            </div>
                                            <div className="checkbox_block">
                                                <span className="checkbox_clone"/>
                                            </div>
                                        </div>
                                        :
                                        <div className={`caregivers-detail_block`}>
                                            <div className="profile_pic"><img src={car?.profile ? car?.profile : generateAvatar(car?.first_name + " " + car?.last_name)} alt=''/></div>
                                            <div className="caregivers_detail">
                                                <h6 className={'text_capitalize'}>{car?.first_name + " " + car?.last_name}</h6>
                                                {
                                                    car?.phone &&
                                                    <span className="mobile_num comman_detail">
                                            <img src={mobile_icon} alt={'mobile_icon'}/>{formatPhoneNumber(car?.phone)}
                                        </span>
                                                }
                                                <span className="mail comman_detail">
                                            <img src={mail_icon} alt={'mail_icon'}/>{car?.email}
                                        </span>
                                                <span className="user_detail comman_detail">
                                            <img
                                                src={user_icon} alt={'user_icon'}/>{car?.relationship}</span>
                                            </div>
                                            <div className="checkbox_block">
                                                <span className="checkbox_clone"/>
                                            </div>
                                        </div>
                                )
                            })
                            :
                            <div className={'text_center'}>No Data Found</div>
                        }
                    </div>
            }

        </div>
    )
}

export default CaregiversPop
