import {
    ADD_PATIENTS_LIST, BULK_UPLOAD_PATIENT,
    DELETE_PATIENTS_LIST,
    EDIT_PATIENTS_DETAIL,
    GET_PATIENTS_DETAIL,
    GET_PATIENTS_LIST, SEARCH_PATIENTS, UPDATE_PATIENTS_DETAIL, UPDATE_PATIENTS_LIST_STATUS,GET_CAREGIVERS_DATA
} from "../../actionTypes";

let initialState = {
    patientsList: [],
    patientDetail: {},
    edit_patients: {}
}

export const patientsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_PATIENTS_LIST: {
            return {
                ...state,
                patientsList: payload
            }
        }
        case ADD_PATIENTS_LIST: {
            let temp = { ...state }
            Object.assign(payload,{user_id:payload._id})
            let result = [{ ...payload }, ...temp.patientsList]
            return {
                ...state,
                patientsList: result
            }
        }
        case GET_PATIENTS_DETAIL: {
            return {
                ...state,
                patientDetail: payload
            }
        }
        case DELETE_PATIENTS_LIST: {
            let temp = state.patientsList.filter(item => item.user_id !== payload)
            return {
                ...state,
                patientsList: temp
            }
        }
        case EDIT_PATIENTS_DETAIL: {
            return {
                ...state,
                edit_patients: payload
            }
        }
        case SEARCH_PATIENTS: {
            return {
                ...state,
                patientsList: payload
            }
        }
        case UPDATE_PATIENTS_LIST_STATUS: {
            let temp = { ...state };
            let index = state.patientsList.findIndex(data => data.user_id === payload?.id);
            if (index > -1) {
                temp.patientsList[index].isActive[0].isActive = payload.status
            }
            return temp
        }
        // case BULK_UPLOAD_PATIENT:{
        //     return{
        //         ...state,
        //         patientsList: payload
        //     }
        // }
        case GET_CAREGIVERS_DATA  : {
            return {
                ...state,
                caregiver:payload
            }
        }
        default:
            return state
    }
}