import { LOGIN_SUCCESS, SIGNUP_SUCCESS, RESEND_SUCCESS, FORGOT_PASSWORD } from '../../actionTypes';
import Cookies from 'js-cookie'

// export const updateUserDetails = (payload) => ({
//     type: LOGIN_SUCCESS,
//     currentUser: payload,
// })

export const userLogin = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/login", payload)
        .then((res) => {
            if (res.status === 200) {
                // dispatch(updateUserDetails(res.data.data))
                localStorage.setItem("hap_cl_token", res.data.data.token)
                Cookies.set("x-access-token", res.data.data.token, { path: "/", expires: 1 / 2 });
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};

export const getProfileClinic = () => async (dispatch, getState, api) => {
    return await api
        .get("/clinic/getProfile")
        .then((res) => {
            if (res.status === 200) {
                const token = Cookies.get('x-access-token_new');
                if(token){
                    localStorage.setItem('hap_cl_token', token)
                    // Cookies.remove('x-access-token_new', {domain: process.env.REACT_APP_DOMAIN})
                }
                dispatch({
                    type: LOGIN_SUCCESS,
                    currentUser: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const userSignup = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/register", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: SIGNUP_SUCCESS,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};

export const reSendOTP = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/resendOtp", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: RESEND_SUCCESS,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};

export const verifyOTP = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/verifyOtp", payload)
        .then((res) => {
            if (res.status === 200) {
                //localStorage.setItem("userDetail", JSON.stringify(res.data.data))
                localStorage.setItem("hap_cl_token", res.data.data.token)
                Cookies.set("x-access-token", res.data.data.token, { path: "/", expires: 1 / 2 });
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};

///clinic/requestPasswordReset

export const forgotPassword = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/requestPasswordReset", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: FORGOT_PASSWORD,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};
//

export const changePassword = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/change-password", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};
// /clinic/resetPassword

export const ResetPasswordData = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/resetPassword", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};