import {
    GET_CLINIC_DETAILS,
    ADD_PHYSICIAN_CLINIC,
    EDIT_AUTH_USER,
    ADD_AUTHORIZED,
    UPDATE_AUTH_USER,
    UPDATE_AUTH_USER_STATUS,
    DELETE_AUTH_USER,
    CHANGE_PRIMARY_AUTH_USER,
    GET_COUNTRY_DATA,
    GET_STATE_DATA,
    EDIT_CLINIC_DETAILS,
    UPDATE_CLINIC,
    CLEAR_CLINIC,
    SEARCH_PHAYSICIAN_PATIENTS,
    GET_CLINIC_PROFILE,
    LOGIN_SUCCESS, GET_MINUTE_DATA,UPDATE_CLINIC_MINUTE_PLAN,CANCEL_CLINIC_MINUTE_PLAN
} from "../../actionTypes";
import { jsonToFormData } from "../../../utils";

export const getClinicDetails = () => async (dispatch, getState, api) => {
    return await api
        .get("/clinic/getClinicDetails")
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_CLINIC_DETAILS,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
//addAuthorizedPerson
export const addPhysician = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/addPhysician", jsonToFormData(payload))
        .then((res) => {
            if (res.status === 201) {
                dispatch({
                    type: ADD_PHYSICIAN_CLINIC,
                    payload: res.data?.data?.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
export const addAuthorizedPerson = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/addAuthorizedUser", jsonToFormData(payload))
        .then((res) => {
            if (res.status === 201) {
                dispatch({
                    type: ADD_AUTHORIZED,
                    payload: res.data.data,
                })
            }

            return res
        })
        .catch((err) => {
            return err.response;
        });
};
export const editAuthUser = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/editAuthorizedUser", payload)
        .then((res) => {
            dispatch({
                type: EDIT_AUTH_USER,
                payload: res.data.data,
            })
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
export const updateAuthUser = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/updateAuthorizedUser", jsonToFormData(payload))
        .then((res) => {
            dispatch({
                type: UPDATE_AUTH_USER,
                payload: payload,
            })
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
export const updateAuthUserStatus = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/updateAuthorizedUserStatus", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: UPDATE_AUTH_USER_STATUS,
                    payload: payload,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
export const deleteAuthorizedUser = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/deleteAuthorizedUser", payload)
        .then((res) => {
            if (res.status === 201) {
                dispatch({
                    type: DELETE_AUTH_USER,
                    payload: payload,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
//changePrimaryAuthUser
export const changePrimaryAuthUser = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/changePrimaryUser", payload)
        .then((res) => {
            dispatch({
                type: CHANGE_PRIMARY_AUTH_USER,
                payload: payload,
            })
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const getCountryData = () => async (dispatch, getState, api) => {
    return await api
        .get("/clinic/getCountryData")
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_COUNTRY_DATA,
                    payload: res.data.data.countries,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};

export const getStateData = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/getStateData", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_STATE_DATA,
                    payload: res.data.data.states,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};

///clinic/editClinic
export const editClinicDetail = (payload) => async (dispatch, getState, api) => {
    return await api
        .get("/clinic/editClinic")
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: EDIT_CLINIC_DETAILS,
                    payload: res.data.data,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};
///clinic/updateClinic

export const updateclinicList = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/updateClinic", jsonToFormData(payload))
        .then((res) => {
            if (res.status === 201) {
                dispatch({
                    type: UPDATE_CLINIC,
                    payload: payload,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
export const clearClinic = (data) => ({
    type: CLEAR_CLINIC,
    payload: data,
})
//filterSearch /clinic/resendInvitationAuthorizedUser
export const resendinvItationAuthorized = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/resendInvitationAuthorizedUser", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const getMinutes = () => async (dispatch, getState, api) => {
    return await api
        .get("/clinic/getMinutesData")
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_MINUTE_DATA,
                    payload: res.data.data?.meetingMinutes,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};

export const updateMinutesDetails = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/changeMinutePlan", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: UPDATE_CLINIC_MINUTE_PLAN,
                    payload: res.data.data.minute,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const cancelMinutesPlan = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/cancelMinutePlan", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: CANCEL_CLINIC_MINUTE_PLAN,
                    payload: null,
                })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
//

export const resendInvitationUserList = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/clinic/resendInvitationAuthorizedUser", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};