import React from 'react';
// import Modal from 'react-modal';
import { Modal } from 'antd';

const CustomModal = ({ children, modalIsOpen, handleOpenModal, className, modalName }) => {

    return (
        <>
            <Modal maskClosable={false} className={className} closable={(modalName === ("AccountcredentialsPop")) ? false : modalName === 'VerificationCodePop' ? false : true} footer={null} centered={(modalName === 'DeleteAlert' || modalName === "ErrorPop") ? 'centered' : ""} visible={modalIsOpen}
                onCancel={() => handleOpenModal()} >
                <div className="modal_dialog">
                    {children}
                </div>
            </Modal>
            {/*<Modal*/}
            {/*    isOpen={modalIsOpen}*/}
            {/*    className={className}*/}
            {/*    onRequestClose={closeModal}*/}
            {/*    contentLabel="Example Modal"*/}
            {/*>*/}
            {/*     <button onClick={closeModal}>close</button>*/}

            {/*    {children}*/}


            {/*    /!* 1. Invalid Account credentials *!/*/}
            {/*    /!* <div className="custom_modal text_center">*/}
            {/*        <h3 className='mb_5'>Invalid Account credentials</h3>*/}
            {/*        <p className='mt_3'>We are unable to locate an account with the credentials provided. Please try again or click on Forgot Password to reset password.</p>*/}
            {/*        <div className="form_group d_flex justify_content_center form_action mt_4">*/}
            {/*            <button className='btn btn_default'>Clear</button>*/}
            {/*            <button className='btn btn_primary ml_2' >Next</button>*/}
            {/*        </div>*/}
            {/*    </div> *!/*/}
            {/*    /!* |END| Invalid Account credentials *!/*/}



            {/*    /!* 2. Authenticate your email *!/*/}
            {/*    /!* <div className="custom_modal text_center">*/}
            {/*        <h3 className='mb_5'>Authenticate your email</h3>*/}
            {/*        <div className="otpMsg mt_2 pl_4 pr_4">*/}
            {/*            <form>*/}
            {/*                <p className='text_red'>This 5-digit code has either expired or not found in our database. Please click below to resend code</p>*/}
            {/*                <p>Please enter the 5-digit code sent to your email for account verification</p>*/}

            {/*                <div className="form_row mt_2">*/}
            {/*                    <div className="form_group">*/}
            {/*                        <input type="text" name="" id="" className="form_control" value="4" />*/}
            {/*                    </div>*/}
            {/*                    <div className="form_group">*/}
            {/*                        <input type="text" name="" id="" className="form_control" value="5" />*/}
            {/*                    </div>*/}
            {/*                    <div className="form_group">*/}
            {/*                        <input type="text" name="" id="" className="form_control" />*/}
            {/*                    </div>*/}
            {/*                    <div className="form_group">*/}
            {/*                        <input type="text" name="" id="" className="form_control" />*/}
            {/*                    </div>*/}
            {/*                    <div className="form_group">*/}
            {/*                        <input type="text" name="" id="" className="form_control" />*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <p>Resend Code</p>*/}
            {/*                <div className="form_group d_flex justify_content_center form_action mt_2">*/}
            {/*                    <button className='btn btn_default'>Clear</button>*/}
            {/*                    <button className='btn btn_primary ml_2' >Next</button>*/}
            {/*                </div>*/}
            {/*            </form>*/}
            {/*        </div>*/}
            {/*    </div> *!/*/}
            {/*    /!* |END| Authenticate your email *!/*/}


            {/*    /!* 3. Delete Patient *!/*/}
            {/*    /!*<div className="custom_modal text_center">*!/*/}
            {/*    /!*    <h3 className='mb_5'>Delete Patient</h3>*!/*/}
            {/*    /!*    <p className='mt_3'>You are about to delete an active patient from your list. Pnce you delete the patient, all calendar invites will be removed from your and the patient’s schedule. This change can not be reversed.</p>*!/*/}
            {/*    /!*    <p className='mt_2'><strong>Do you want to proceed?</strong></p>*!/*/}
            {/*    /!*    <div className="form_group d_flex justify_content_center form_action mt_4">*!/*/}
            {/*    /!*        <button className='btn btn_default'>Yes</button>*!/*/}
            {/*    /!*        <button className='btn btn_primary ml_2' >No</button>*!/*/}
            {/*    /!*    </div>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!* |END| Delete Patient *!/*/}
            {/*</Modal>*/}
        </>
    )
}

export default CustomModal
