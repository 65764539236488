import Header from './Header';
import Footer from './Footer';
import React, { Suspense, useEffect, useState } from 'react';
import { isAuthenticated } from "../utils";
import Cookies from 'js-cookie';
import Sidebar from "./Sidebar";
import { getProfileClinic } from '../redux/auth/action';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';


const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [active, setActive] = useState(true)


    const handleLogout = () => {
        Cookies.remove('x-access-token_new', { path: '/', domain: process.env.REACT_APP_DOMAIN })
        Cookies.remove('x-access-token', { path: '/' })
        localStorage.clear()
        history.push('/');
        setActive('logout')
        setIsMenuOpen(false)
    };

    useEffect(() => {
        if (isAuthenticated()) {
            dispatch(getProfileClinic())
        }
    }, [isAuthenticated()])

    return (
        <div className={`${history.location.pathname.includes('invoice') ? 'layout invoice' : 'layout'}`}>
            {
                history.location.pathname.includes('invoice') ?
                    ""
                    :
                    <Header
                        isAuthenticated={isAuthenticated()}
                        handleLogout={handleLogout}
                        setIsMenuOpen={setIsMenuOpen}
                        isMenuOpen={isMenuOpen}
                        active={active}
                    />
            }

            <section className={isAuthenticated() ? "page_wrapper" : " inner_wrapper"}>
                {history.location.pathname.includes('invoice') ?
                    " "
                    :
                    isAuthenticated() && <Sidebar
                        history={history}
                    />
                }
                <Suspense fallback={""}>
                    {children}
                </Suspense>
            </section>
            {/* <Footer /> */}

        </div>
    )
}

export default Layout;