import {
    GET_PHYSICIANS_LIST,
    DELETE_PHYSICIAN_LIST,
    UPDATE_STATUS_PHYSICIAN_LIST,
    GET_PHYSICIAN_DETAIL,
    EDIT_PHYSICIAN_DETAIL,
    UPDATE_PHYSICIAN_DETAIL, SEARCH_PHYSICIAN,
    GET_PHYSICIAN_PATIENTS, SEARCH_PHYSICIAN_PATIENTS, ADD_PHYSICIAN_MINUTES, ADD_PHYSICIAN
} from "../../actionTypes";

let initialState = {
    physicianList: [],
    physicianDetail: {},
    editPhysician: {},
    getPhyPatients: []
}

export const physicianReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case GET_PHYSICIANS_LIST: {
            return {
                ...state,
                physicianList: payload
            }
        }
        case DELETE_PHYSICIAN_LIST: {
            let temp = state.physicianList.filter(item => item.user_id !== payload.id)
            return {
                ...state,
                physicianList: temp
            }
        }
        case UPDATE_STATUS_PHYSICIAN_LIST: {
            let temp = { ...state };
            let index = state.physicianList.findIndex(data => data.user_id === payload?.id);
            if (index > -1) {
                temp.physicianList[index].isActive[0].isActive = payload.status
            }
            return temp
        }
        case GET_PHYSICIAN_DETAIL: {
            return {
                ...state,
                physicianDetail: payload
            }
        }
        case EDIT_PHYSICIAN_DETAIL: {
            return {
                ...state,
                editPhysician: payload
            }
        }
        case UPDATE_PHYSICIAN_DETAIL: {
            if (payload.type) {
                let temp = { ...state }
                let index = state.physicianList.findIndex(item => item.user_id === payload.result.physician._id)
                if (index > -1) {
                    temp.physicianList[index] = { ...temp.physicianList[index], ...payload.result.physician }
                }
                return temp
            } else {
                return {
                    ...state,
                    physicianDetail: payload.result.physician
                }
            }
        }
        case SEARCH_PHYSICIAN: {
            return {
                ...state,
                physicianList: payload
            }
        }

        case GET_PHYSICIAN_PATIENTS: {
            return {
                ...state,
                getPhyPatients: payload
            }
        }
        case SEARCH_PHYSICIAN_PATIENTS: {
            return {
                ...state,
                getPhyPatients: { ...state.getPhyPatients, patients: [...payload] }
            }
        }

        case ADD_PHYSICIAN_MINUTES: {
            let tempData = { ...state }
            let index = state.physicianList.findIndex(data => data.user_id === payload.pysician_id)
            if (index > -1) {
                tempData.physicianList[index].billing_period_meeting_minutes = tempData.physicianList[index].billing_period_meeting_minutes + payload.minutes
                tempData.physicianList[index].availableMinutes = tempData.physicianList[index].availableMinutes +  payload.minutes
            }
            return tempData;
        }
        case ADD_PHYSICIAN: {
            Object.assign(payload.physician,{billing_period_meeting_minutes:payload?.billing_period_meeting_minutes})
            Object.assign(payload.physician,{clinic_meeting_minutes:payload?.clinic_meeting_minutes})
            Object.assign(payload.physician,{user_id:payload.physician._id})
            let result = [...state.physicianList, { ...payload.physician }]
            return {
                ...state,
                physicianList: result
            }
        }
        default:
            return state
    }
}

