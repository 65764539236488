export const links = [
    {
        id: 1,
        name: "physicians",
        route: "/physicians"
    },
    {
        id: 2,
        name: "patients",
        route: "/patients"
    }
];