import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Validation from "../../Validation";
import { useDispatch } from "react-redux";
import { changePrimaryAuthUser } from "../../../redux/clinic/action";
import CustomModal from "../../../hoc/CustomModal";
import PopComponent from "../../../hoc/PopContent";
import FilledButton from "../../FilledButton";
import { Form, Input } from "antd";


const SwitchPrimary = ({ modalValue, handleOpenModal, clinicId }) => {
    const dispatch = useDispatch()
    const [modalName, setModalName] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [modalVal, setModalVal] = useState('')
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});
    let ModalData = PopComponent[modalName]

    useEffect(() => {
        setFormData({})
        form.resetFields();
    }, [handleOpenModal])
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    };
    const handleCloseModal = () => {
        setFormData({})
        form.resetFields();
    }
    const handleSignIn = (value) => {
        Object.assign(modalValue, { password: value?.password })
        delete modalValue.clinic_id;
        setLoading(true)
        dispatch(changePrimaryAuthUser(modalValue))
            .then(res => {
                if (res.status === 400) {
                    setLoading(false)
                    handleOpenModalError('CommonPop', { header: "Info", body: res.data.message, auth: true })
                } else if (res.status === 201) {
                    handleOpenModal('CommonPop', { header: "Success", body: res.data.message, auth: true })
                    setLoading(false)
                }
            }).cache(e => {
                setLoading(false)
                handleOpenModalError('CommonPop', { header: "Error", body: e.message, auth: true })
            })
    }

    const handleOpenModalError = (type, data) => {
        switch (type) {
            case "CommonPop": {
                setModalVal(data)
                setModalName(type);
                setModalOpen(true)
            }
                break;
            default: {
                setModalOpen(!modalOpen);
            }
        }
    }
    return (
        <>
            <div>
                <div className="primarycontact_box">
                    <div className="primarycontact_heading"><h5>Switch Primary Contact</h5></div>
                    <div className=" primarycontact_subheading"><p>You are about to change the primary contact on this account. This will remove your ability to add authorized users and give that permission to new primary contact.</p></div>
                    <div className="primarycontact_text"><h6>If you want to proceed, please insert your password to proceed.</h6></div>
                    <Form
                        form={form}
                        onFinish={handleSignIn}
                        layout="vertical"
                        autoComplete="off"
                        initialValues={formData}
                        onReset={() => handleCloseModal()}

                        className="switch_primary_box signup_clinic switch-primary_form">

                        <Form.Item

                            name="password"
                            onChange={(e) => handleChange(e)}
                            rules={[
                                { required: true, message: 'Please input your password!' },
                            ]}
                        >
                            <Input.Password
                                name="password"
                                onChange={(e) => handleChange(e)} placeholder={'Password'} />
                        </Form.Item>

                        <div className="form_group d_flex justify_content_center form_action">
                            <button className="btn btn_default" type="reset" onClick={() => handleOpenModal()}>Cancel</button>
                            <FilledButton type={'submit'} loading={loading} value={'Submit'} className="btn btn_primary ml_2" />
                        </div>
                    </Form>
                </div>
            </div>
            <CustomModal className={"modal errorPop"} modalName={modalName} modalIsOpen={modalOpen} handleOpenModal={handleOpenModalError}>
                <ModalData handleOpenModal={handleOpenModalError} modalVal={modalVal} loading={loading} />
            </CustomModal>
        </>
    )
}
export default SwitchPrimary